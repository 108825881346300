<template>
    <div style="width: 1200px; height: 680px; border-radius: 12px; background: #4C9FF2; position: relative;">
        <!--顶部-->
        <div style="width: 100%; height: 120px; display: flex; border-top-left-radius: 12px; border-top-right-radius: 12px;">
            <div style="width: 420px; height: 100%; border-top-left-radius: 12px;" class="come">
                <div class="vip" style="width: 120px; height: 116px; position: relative; display: flex; justify-content: center; align-items: center; border-top-left-radius: 12px;">
                    <div style="width: 160px;line-height: 20px;margin-top: -40px;color: #FFFFFF;font-size: 20px;font-weight: 500;margin-left: 25px;">对公支付</div>
                </div>
            </div>
            <div style="width: 420px; height: 120px; margin-left: 360px;border-radius: 8px;">
                <div class="bgRight" style="width: 100%; height: 120px;border-radius: 8px;">
                    <div @click="onClose" style="width: 24px;height: 24px;float: right;margin-top: 15px;margin-right: 15px;cursor: pointer;">
                        <svg class="close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.31891 6.31879C6.54302 6.09467 6.90639 6.09467 7.13051 6.31879L12.0001 11.1884L16.8697 6.31879C17.0938 6.09467 17.4572 6.09467 17.6813 6.31879C17.9054 6.54291 17.9054 6.90627 17.6813 7.13039L12.8117 12L17.6813 16.8696C17.9054 17.0937 17.9054 17.4571 17.6813 17.6812C17.4572 17.9053 17.0938 17.9053 16.8697 17.6812L12.0001 12.8116L7.13051 17.6812C6.90639 17.9053 6.54302 17.9053 6.31891 17.6812C6.09479 17.4571 6.09479 17.0937 6.31891 16.8696L11.1885 12L6.31891 7.13039C6.09479 6.90627 6.09479 6.54291 6.31891 6.31879Z" fill="white"></path></svg>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 1120px; height: 560px; background: #FFFFFF; position: absolute; top: 60px; left: 40px;border-radius: 8px;display: flex;text-align: left;">
            <div style="width: 480px;height: 100%;">
                <div style="width: 384px;height: 490px;margin: 32px 48px;">
                    <div style="width: 100%;height: 50px;font-size: 20px;line-height: 20px;color: rgb(53, 68, 93);font-weight: 500;">对公转账流程</div>
                    <div style="width: 384px;height: 272px;">
                        <div style="width: 100%;line-height: 21px;margin-bottom: 16px;font-weight: 600;font-size: 16px;">第一步：对公汇款</div>
                        <ul class="detailWrap">
                            <li><span class="left">账户名称：</span><span class="right">北京无界智谷科技有限公司</span></li>
                            <li><span class="left">开户银行：</span><span class="right">招商银行股份有限公司北京首体支行</span></li>
                            <li><span class="left">银行账号：</span><span class="right">1109 3374 1910 301</span></li>
                            <li><span class="left">汇款金额：</span><span class="right">¥{{utils.keepTwoDecimalFull(info.price)}}</span></li>
                            <li><span class="left">汇款备注：</span><span class="right">订单号{{info.orderNo}}  </span></li>
                            <li>
                                <div style="width: 216px;height: 19px;display: flex;">
                                    <div style="width: 13px;height: 13px;margin-right: 6px;margin-top: 1px">
                                        <img src="@/assets/imgs/vip/jinggao.png" style="width: 100%;height: 100%">
                                    </div>
                                    <div>请在对公汇款时务必备注订单号</div>
                                </div>
                            </li>
                        </ul>
                        <div @click="copyText" @mouseenter="isCopyColor = true" @mouseleave="isCopyColor = false" :style="isCopyColor == true ? 'background:#459DFD' : ''" style="width: 82px;height: 32px;background: rgb(22, 132, 252);border-radius: 6px;margin-top: 20px;text-align: center;line-height: 32px;color: #FFFFFF;font-size: 14px;cursor: pointer;">
                            一键复制
                        </div>
                    </div>
                    <div style="width: 384px;height: 111px;margin-top: 55px;">
                        <div style="font-size: 16px;font-weight: 600;">第二步：升级账号</div>
                        <div style="width: 100%;height: auto;margin-top: 16px;">
                            <div style="width: 100%;line-height: 25px;">转账完成后，请提供订单号及银行盖章回执单，联系<span style="color: rgb(22, 132, 252);cursor: pointer;">在线客服</span></div>
                            <div style="width: 100%;line-height: 25px;">或发送相关信息至<span style="color:rgb(22, 132, 252);cursor: pointer;">@wondershare.com</span></div>
                            <div style="width: 100%;line-height: 25px;">我们将尽快为您升级账号。</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="boundary"></div>
            <div style="width: 480px;height: 100%;">
                <div style="width: 400px;height: 430px;padding: 80px 0px 0px 80px;">
                    <div style="width: 400px;height: 70px;margin-bottom: 22px;font-weight: 500;">
                        <div style="width: 100%;height: 22px;display: flex;">
                            <div style="width: 22px;height:22px;align-items:center;">
                                <svg xmlns="http://www.w3.org/2000/svg" class="svg__StyledSvg-sc-5p68xx-0 gSyYMA svg-icon ext00/order/bankwire icon" viewBox="0 0 32 22" aria-hidden="true"><path d="M29.18 0H2.825A2.816 2.816 0 000 2.809v16.383A2.816 2.816 0 002.824 22H29.18a2.816 2.816 0 002.823-2.808V2.809a2.8 2.8 0 00-.826-1.986A2.83 2.83 0 0029.181 0zm-1.882 17.79h-6.59v-3.278h6.59v3.277zm3.294-9.364H1.412V4.682h29.18v3.744z" fill="#0087F2" fill-rule="nonzero"></path></svg>
                            </div>
                            <div style="font-size: 16px;line-height: 16px;margin: 0px 12px;">对公支付</div>
                        </div>
                        <div style="width: 100%;height: 32px;color: rgb(93, 111, 143);margin-top: 18px;">订单号{{info.orderNo}}</div>
                    </div>
                    <div style="width: 100%;height: 270px;color:rgb(93, 111, 143);font-size: 14px;font-weight: 500; ">
                        <div style="margin-bottom: 6px;">您可以复制左侧信息进行对公转账。</div>
                        <div style="margin-bottom: 6px;">转账完成后联系在线客服。</div>
                        <div style="margin-bottom: 6px;color: rgb(158, 169, 188);">对公支付订单下单后7天内有效，超时支付请联系在线客服处理。</div>
                        <div style=" width: 200px;height: 200px;background: #8AB8B8;margin: 0 auto;margin-bottom: 6px;margin-top: 10px;">
                            <img src="@/assets/imgs/vip/xcxcode4.jpg" style="width: 100%;height: 100%">
                        </div>
                        <div @mouseenter="isArtificialColor = true" @mouseleave="isArtificialColor = false" :style="isArtificialColor == true ? 'background:#E8F3FF' : ''" style="width: 82px;height: 32px;line-height: 32px;text-align: center;border-radius: 6px;color:rgb(93, 111, 143);background: rgb(245, 248, 253);border: 1px solid rgb(208, 230, 254);margin: 0 auto;margin-top: 20px;cursor: pointer; ">
                            在线客服
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
export default {
    props:{
        info:{
            type: Object,
            default() {}
        },
        onClose:{
            type:Function,
            default(){}
        }
    },
    data() {
        return {
            isArtificialColor:false,
            isCopyColor:false,
            text:"账户名称：北京无界智谷科技有限公司\n" +
                "开户银行：\n" +
                "招商银行股份有限公司北京首体支行\n" +
                "银行账号：\n" +
                "1109 3374 1910 301\n" +
                "汇款金额：\n" +
                "¥\n" + this.info.price +
                "汇款备注：\n" +
                "订单号:" + this.info.orderNo,
        }
    },
    mounted() {

    },
    methods:{
        copyText() {
            const text = this.text;
            const input = document.createElement("input");
            input.setAttribute("value", text);
            document.body.appendChild(input);
            input.select();
            document.execCommand("copy");
            document.body.removeChild(input);
            this.utils.sus("复制成功");
        }

    }
};
</script>

<style scoped>
.come{
    background-image: url('../../../../../assets/imgs/vip/header_left.png');
    background-size: 100% 100%;
}
.vip{
    background-image: url('../../../../../assets/imgs/vip/header_diamond_success.png');
    background-size: 100% 100%;
}
.el-dialog__header{
    padding: 0px;
}
.el-dialog__body {
    padding: 0px;
}
.bgRight {
    background-image: url('../../../../../assets/imgs/vip/header_right_success.png');
    background-size: 100% 100%;
}
ul{
    list-style: none;
}
.detailWrap li {
    width: 100%;
    max-width: 360px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
}
.detailWrap li .left{
    color: rgb(93, 111, 143);
}
.detailWrap li .right{
    color: rgb(53, 68, 93);
    line-height: 20px;
}
.boundary {
    width: 1px;
    height: 480px;
    flex-shrink: 0;
    background: rgb(229, 229, 229);
    margin-top: 37px;
    margin-left: 80px;
}
</style>

<script setup>
import VueQr from "_vue-qr@2.5.0@vue-qr";
import {getYear} from "../../../../../utils/utils";
</script>
