<template>
	<div id="videoCall">
		<pre>{{cameraId}}</pre>
		<div id="bigPanel" v-show="videoCall.status > 0"  v-drag ref="bigPanel">

			<div id="UserBoxNumber" :class="{
				'UserBoxNumber2':videoCall.userList.length === 2,//私聊
				'UserBoxNumber4':videoCall.userList.length > 2 && videoCall.userList.length <= 4,//2*2宫格
				'UserBoxNumber9':videoCall.userList.length >= 5 && videoCall.userList.length <= 9,//9宫格
				'UserBoxNumber16':videoCall.userList.length >= 10 && videoCall.userList.length <= 16,//4*4宫格
				'UserBoxNumber25':videoCall.userList.length >= 17 && videoCall.userList.length <= 25,//5*5宫格
				'UserBoxNumber36':videoCall.userList.length >= 26 && videoCall.userList.length <= 36,//6*6宫格
				'UserBoxNumber49':videoCall.userList.length >= 37 && videoCall.userList.length <= 49,//7*7宫格
				'UserBoxNumber56':videoCall.userList.length >= 50 && videoCall.userList.length <= 56 //7*8宫格 TRTC限制最多50人
			}">
				<div class="box" :class="{
					'myCam':user.uuid === me.uuid,
					'fullScreen':user.uuid === fullScreenUUID
				}" :style="{backgroundImage:'url('+user.headIco+')'}" :id="'userCam'+user.uuid" v-for="(user,index) in videoCall.userList"   >

					<div class="screen" :id="'userScreen'+user.uuid"></div>

					<div class="nickName" >
						<span>{{user.nickName}}</span>
						<img :src="'https://oss.baigongbao.com/webImg/wifi'+networkQuality+'.png'" v-if="user.uuid === me.uuid">
					</div>

					<el-dropdown trigger="click" style="position: absolute;right:0;top:0;z-index: 2" v-show="videoCall.userList[0].uuid === me.uuid && me.uuid !== user.uuid && videoCall.userList.length > 2">
						<div class="set op sc hand" ><i class="el-icon-s-tools"></i></div>
						<el-dropdown-menu slot="dropdown">


							<el-dropdown-item v-if="user.uuid !== me.uuid" @click.native="removeUser(user.uuid)"   >移除{{user.nickName}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>

					<div class="op sc hand fullScreen" @click="fullScreen(user.uuid)" v-if="me.uuid != user.uuid"><i class="el-icon-full-screen"></i> </div>


				</div>
			</div>



			<div id="bottomBtns">
				<!-- 接听 -->
				<div class="btn open op sc hand" v-if="videoCall.status === 2" @click="confirmVideoCall">
					<i class="el-icon-phone"></i>
				</div>
				<!-- 挂断 -->
				<div class="btn close op sc hand" @click="closeVideoCall">
					<i class="el-icon-phone" style="rotate: 135deg;"></i>
				</div>
				<!-- 摄像头 -->
				<div class="btn op sc hand" @click="useCamera=!useCamera"  v-if="videoCall.status !== 2">
					<i class="el-icon-video-camera"></i>
					<div class="delete" v-if="!useCamera"></div>
				</div>
				<!-- 麦克风 -->
				<div class="btn op sc hand" @click="useMicrophone=!useMicrophone" v-if="videoCall.status !== 2">
					<i class="el-icon-microphone"></i>
					<div class="delete" v-if="!useMicrophone"></div>
				</div>

				<!-- 加人 -->
				<div class="btn op sc hand" v-if="videoCall.status !== 2" @click="inviteShow = true">
					<i class="el-icon-plus"></i>
				</div>

				<!-- 屏幕分享 -->
				<div class="btn op sc hand" v-if="videoCall.status !== 2" @click="shareScreen">
					<i class="el-icon-monitor"></i>
				</div>

				<!-- 切换输入源 -->
				<div class="btn op sc hand" v-if="videoCall.status !== 2" @click="setsShow = true">
					<i class="el-icon-menu"></i>
				</div>

			</div>
		</div>

		<el-dialog :close-on-click-modal="false" :visible.sync="inviteShow" width="1000px" title="邀请加入">
			<div id="invite">
				<!--邀请加入-->
<!--				<el-tabs  @tab-click="onClickTab">-->
<!--					<el-tab-pane :id="item.id" :label="item.name" v-for="item in roomList"></el-tab-pane>-->
<!--				</el-tabs>-->


				<div id="roomUserList">


					<div class="roomUser" v-for="item in roomUserList.adminList">
						<img :src="item.headIco">
						<div class="nickName">{{item.nickName}}</div>
						<div class="btn op sc hand" @click="inviteUser(item.uuid)">邀请</div>
					</div>
<!--					<div class="roomUser" v-for="item in roomUserList.followList">-->
<!--						<img :src="item.headIco">-->
<!--						<div class="nickName">{{item.nickName}}</div>-->
<!--						<<div class="btn op sc hand" @click="inviteUser(item.uuid)">邀请</div>-->
<!--					</div>-->
					<div class="roomUser" v-for="item in roomUserList.userList">
						<img :src="item.headIco">
						<div class="nickName">{{item.nickName}}</div>
						<div class="btn op sc hand" @click="inviteUser(item.uuid)">邀请</div>
					</div>
				</div>
			</div>
		</el-dialog>

		<el-dialog :close-on-click-modal="false" :visible.sync="setsShow" title="设置" width="1000px">
			<div id="sets" >
				<!--设置-->
				<el-tabs v-model="setsActive">
					<el-tab-pane label="切换摄像头" name="1">

						<el-radio-group v-model="cameraId" >
							<el-radio :label="item.deviceId" border v-for="(item,index) in cameraList" @click="changeCamera(item)">{{ item.label }}</el-radio>
						</el-radio-group>

					</el-tab-pane>
					<el-tab-pane label="切换麦克风" name="2">
						<el-radio-group v-model="microphoneId" >
							<el-radio :label="item.deviceId" border v-for="(item,index) in microphoneList" @click="changeCamera(item)">{{ item.label }}</el-radio>
						</el-radio-group>

					</el-tab-pane>
				</el-tabs>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Bottom from "@/components/common/Bottom.vue";
	import TRTC from 'trtc-sdk-v5';
	import LibGenerateTestUserSig from "@/utils/lib-generate-test-usersig.min";
	import newApi from "@/utils/newApi";

	export default {
		name: "VideoCall",
		components: {Bottom},
		data(){
			return{
				fullScreenUUID:"",
				networkQuality:0,//网络状态
				useCamera:false,//启用摄像头
				useMicrophone:false,//启用麦克风
				cameraList:[],//麦克风列表
				microphoneList:[],//摄像头列表--
				trtc:null,
				inviteShow:false,//邀请
				setsShow:false,//设置
				setsActive:"1",//设置中默认打开的选项
				cameraId:"",//当前摄像机
				microphoneId:"",//当前麦克风
				roomList:[],//room列表
				roomUserList:[],//room成员列表
			}
		},
    watch:{
			async cameraId(value, oldvalue) {
				await this.changeCamera(value);
			},
			'videoCall.status'(value, oldvalue) {
				console.log("videoCall.status改变==================状态为", value);
				if (oldvalue === 0 && value === 1) {
					//呼叫他人
					this.enterRoom();
					if (this.videoCall.id !== ''){
					this.getMyDutyRoomList();
				}
				}
				if (oldvalue === 0 && value === 2) {
					//被呼叫
					this.enterRoom();
					if (this.videoCall.id !== ''){
					this.getMyDutyRoomList();
				}
				}
				if (oldvalue === 1 && value === 0) {
					//通话中挂断
					console.log("通话中挂断")
					this.trtc.stopRemoteVideo({userId: '*'});//停止播放所有远端用户
					this.trtc.stopLocalAudio();//关闭本地音频
					this.trtc.stopLocalVideo();//关闭本地视频
					this.trtc.exitRoom();//退出房间
				}
				if (oldvalue === 2 && value === 0) {
					//未接通就挂断
					console.log("未接通就挂断")
					this.trtc.stopRemoteVideo({userId: '*'});//停止播放所有远端用户
					this.trtc.stopLocalAudio();//关闭本地音频
					this.trtc.stopLocalVideo();//关闭本地视频
					this.trtc.exitRoom();//退出房间
				}
			},
			async useCamera(value) {
				console.log("摄像头", value);
				if (value) {
					await this.startCamera(JSON.parse(JSON.stringify(this.cameraList)));
				} else {
					await this.trtc.stopLocalVideo();
				}
			},
			async useMicrophone(value) {
				console.log("麦克风", value);
				if (value) {
					await this.startMicrophone(JSON.parse(JSON.stringify(this.microphoneList)))
				} else {
					await this.trtc.stopLocalAudio();
				}
			}
		},
		mounted() {
			this.startTRTC();

		},
		methods:{
			async shareScreen() {
				console.log("屏幕分享");
				await this.trtc.startScreenShare();
			},
			inviteUser(uuid){
				const that = this;
				this.newApi.inviteUserToVideoCallRoom({
					uuid: uuid,
					roomId: this.videoCall.roomId
				}).then(res => {
					if (res.isSuccess == 1){
						that.toast("邀请成功");
					}
				})
			},
			onClickTab(e){
				this.getRoomUserList(this.videoCall.id);
			},
			getMyDutyRoomList(){
				const that = this;
				this.newApi.getMyDutyRoomList({
					type: 0,
					roomType: 0
				}).then(res => {
					that.roomList = res.data;
					if (res.data.length > 0){
						that.getRoomUserList(res.data[0].id);
					}
				})
			},
			getRoomUserList(){
				const that = this;
				this.newApi.getRoomUserList({
					roomId: this.videoCall.id
				}).then(res => {
					that.roomUserList = res.data;
				})
			},
			async changeCamera(deviceId) {
				// 切换摄像头
				await this.trtc.updateLocalVideo({
					option: {
						cameraId: deviceId
					}
				});
			},
			removeUser(uuid){
				this.newApi.removeUserVideoCallRoom({
					roomId:this.videoCall.roomId,
					uuid:uuid
				})
			},
			async startMicrophone() {
				if (this.microphoneList.length === 0) {
					this.utils.err("没有麦克风");
					return false;
				}
				console.log("尝试开启麦克风>>>>>>" + this.microphoneList[0].label);
				try {
					//尝试开启第一个摄像头
					await this.trtc.startLocalAudio({
						view: document.getElementsByClassName('myCam')[0],
						option: {
							microphoneId: this.microphoneList[0].deviceId,
						}
					});
					console.log("麦克风:" + this.microphoneList[0].label + "开启成功")
					this.utils.sus("麦克风:" + this.microphoneList[0].label + "开启成功");
					this.useMicrophone = true;
					return true;
				} catch (e) {
					console.log("麦克风失败________",e);
					return false;
				}
			},
			async startCamera(cameraList) {
				if (cameraList.length === 0) {
					this.utils.err("没有摄像头");
					return false;
				}
				console.log("尝试开启摄像头>>>>>>" + cameraList[0].label);
				try {
					console.log("尝试开启摄像头>>>>>>" + cameraList[0].label);
					//尝试开启第一个摄像头
					await this.trtc.startLocalVideo({
						view: document.getElementsByClassName('myCam')[0],
						option: {
							cameraId: cameraList[0].deviceId,
						}
					});
					console.log("摄像头:" + cameraList[0].label + "开启成功")
					this.utils.sus("摄像头:" + cameraList[0].label + "开启成功");
					this.cameraId = cameraList[0].deviceId;
					this.useCamera = true;
					return true;
				} catch (e) {
					console.log("错误-------------------------",e.toString())
					if (e.toString().indexOf("already started") > -1){//这个错误实际成功了
						console.log("摄像头:" + cameraList[0].label + "开启成功")
						this.useCamera = true;
						return true;
					}
					console.log("摄像头:" + cameraList[0].label + "开启失败")
					this.utils.err("摄像头:" + cameraList[0].label + "开启失败");
					//开启失败 删掉这个坏的摄像头
					cameraList.splice(0, 1);
					if (cameraList.length > 0) {
						//还有摄像头的话，再开启第一个摄像头
						await this.startCamera(JSON.parse(JSON.stringify(cameraList)));
					} else {
						return false;
					}
				}

			},
			closeVideoCall(){
				//挂断 任何节点都可以挂断
				console.log("here");
				this.newApi.exitVideoCallRoom({
					roomId:this.videoCall.roomId
				})
				this.$store.commit("closeVideoCall");

			},
			confirmVideoCall(){
				//接听
				//不管有没有摄像头和麦克风，接听了就是ok了
				this.$store.commit("setVideoCall",{
					status:1
				})
				//尝试启动摄像头
				this.startCamera(JSON.parse(JSON.stringify(this.cameraList)));
				//尝试启动麦克风
				this.startMicrophone(JSON.parse(JSON.stringify(this.microphoneList)));
			},
			async enterRoom() {
				const that = this;
				const SDKAPPID = 1400629374;
				const EXPIRETIME = 604800;
				const SECRETKEY = '4a0b3152bd395ea70744d1fa4ecd343668f26ab33d41ec465d2b928d00c12751';
				const generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME);
				const userSig = generator.genTestUserSig(this.me.uuid);


				// 先获取摄像头
				this.cameraList = await TRTC.getCameraList();

				// 先获取麦克风列表
				this.microphoneList = await TRTC.getMicrophoneList();


				// 在进房前监听网络事件
				this.trtc.on(TRTC.EVENT.NETWORK_QUALITY, event => {
					this.networkQuality = event.uplinkNetworkQuality;
				})

				// 在进房前监听用户发布事件



				this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, event => {
					console.log("用户视频发布",event);

					// 主路视频流，一般是推摄像头的那路流
					if (event.streamType === TRTC.TYPE.STREAM_TYPE_MAIN) {
						// 1. 在页面中放置一个 id 为 `${userId}_main` 的 div 标签，用于在 div 标签内播放主路流。业务侧可自定义 div 标签的 id，此处只是举例说明。
						// 2. 播放主路视频流
						const userId = event.userId;
						const streamType = event.streamType;
						const view = document.getElementById('userCam' + userId);
						this.trtc.startRemoteVideo({
							userId:userId,
							streamType:streamType,
							view:view
						});
					} else {
						// 辅路视频流，一般是推屏幕分享的那路流。
						// 1. 在页面中放置一个 id 为 `${userId}_screen` 的 div 标签，用于在 div 标签内播放屏幕分享。业务侧可自定义 div 标签的 id，此处只是举例说明。
						// 2. 播放屏幕分享
						const userId = event.userId;
						const streamType = event.streamType;
						const view = document.getElementById('userScreen' + userId);
						this.trtc.startRemoteVideo({
							userId:userId,
							streamType:streamType,
							view:view
						});
					}


				});

				//进房
				this.trtc.enterRoom({
					strRoomId: this.videoCall.roomId,
					userId: this.me.uuid,
					scene: 'rtc',
					sdkAppId: SDKAPPID,
					userSig: userSig
				})


				console.log('摄像头列表',this.cameraList);
				console.log('麦克风列表',this.microphoneList);


				if (this.videoCall.status === 1){
					//呼叫他人，直接开启摄像头
					console.log('开启摄像头');

					let res =  await this.startCamera(JSON.parse(JSON.stringify(this.cameraList)));
					if (!res){
						this.utils.err("摄像头全部开启失败");
					}
					res =  await this.startMicrophone(JSON.parse(JSON.stringify(this.microphoneList)));
					if (!res){
						this.utils.err("麦克风全部开启失败");
					}

				}

				if (this.videoCall.status === 2){
					//被呼叫，开启他人摄像头，先不开启自己的

				}

			},
			fullScreen(uuid){
				if(this.fullScreenUUID === ""){
					this.fullScreenUUID = uuid;
					if(document.documentElement.requestFullscreen) {
						document.documentElement.requestFullscreen();
					} else if(document.documentElement.mozRequestFullScreen) {
						document.documentElement.mozRequestFullScreen();
					} else if(document.documentElement.webkitRequestFullscreen) {
						document.documentElement.webkitRequestFullscreen();
					} else if(document.documentElement.msRequestFullscreen) {
						document.documentElement.msRequestFullscreen();
					}
				}else{
					this.fullScreenUUID = "";
					if(document.exitFullscreen) {
						document.exitFullscreen();
					} else if(document.mozCancelFullScreen) {
						document.mozCancelFullScreen();
					} else if(document.webkitExitFullscreen) {
						document.webkitExitFullscreen();
					}
				}
			},
			startTRTC(){
				TRTC.setLogLevel(5);
				this.trtc = TRTC.create();

			}
		},
		computed: {
			videoCall(){
				return this.$store.state.videoCall
			},
			me() {
				return this.$store.state.user
			},
			is_login(){
				return this.$store.state.is_login
			}
		},
		directives:{
			/**
			 * 通话视频页面移动
			 * @param el
			 */
			drag(el){

				const video_call = document.getElementById("bigPanel");

				el.onmousedown = function(e){
					const disx = e.pageX - video_call.offsetLeft;
					const disy = e.pageY - video_call.offsetTop;
					document.onmousemove = function (e){
						if ( (document.documentElement.clientWidth - 334 - (e.pageX - disx) > 0) && (e.pageX - disx > 0) ) {
							video_call.style.left = e.pageX - disx+'px';
						}
						if ( (document.documentElement.clientHeight - 610 - (e.pageY - disy) > 0) && (e.pageY - disy > 0) ) {
							video_call.style.top = e.pageY - disy+'px';
						}
					}
					document.onmouseup = function(){
						document.onmousemove = document.onmouseup = null;
					}
				}

			}
		}
	}
</script>

<style scoped lang="scss">


	#videoCall{
		#bigPanel{
			background: #202020;
			box-shadow: 1px 1px 12px 1px #666;
			position: fixed;
			left: 0;top: 0;
			z-index: 999;
			width: 668px;
			height: 610px;

			.box.fullScreen{
				position: fixed;
				top: 0;left: 0;
				z-index: 1000;
				width: 100% !important; height: 100% !important;
			}
			.box{
				width: 100%; height: 100%;
				background-size: 100%;
				background-position: center;
				background-repeat: no-repeat;
				position: relative;

				.fullScreen{
					color: #FFFFFF;
					position: absolute;
					top: 0;left: 0;
					width: 30px; height: 30px;
					z-index: 2; display: flex;
					justify-content: center; align-items: center;
				}

				.screen{
					position: absolute;
					top: 0;left: 0;
					z-index: 2;
					width: 100%; height: 100%;
				}
				.nickName{
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 3;
					background: rgba(0,0,0,.8);
					width: 100%; height: 20px;line-height:20px; font-size: 12px;
					color: #FFF; overflow: hidden;text-align: center;
					img{
						height: 12px; margin: 4px 0; position: absolute;
						right: 4px;
					}
				}
				.set{
					color: #FFF;
					top: 0;
					right: 0;
					position: absolute;
					text-align: center;
					width: 20px;height: 20px;
					background: rgba(0,0,0,.8);
				}
			}

			.UserBoxNumber2{
				position: relative;
				height: 100%;
				.box{
					width: 100%; height: 100%;
				}
				.box.myCam{
					width: 200px; height: 120px; position: absolute; background-color:#333; top:30px; right:30px; z-index: 4;
				}
			}


			.UserBoxNumber4{
				.box{
					width: calc( 100% - 4px ) / 2 ;
					height: calc( 100% - 4px ) / 2 ;
				}
			}
			.UserBoxNumber9{
				.box{
					width: calc(  100% - 6px ) / 3 ;
					height: calc( 100% - 6px ) / 3 ;
				}
			}
			.UserBoxNumber16{
				.box{
					width: calc(  100% - 8px ) / 4 ;
					height: calc(  100% - 8px ) / 4 ;
				}
			}
			.UserBoxNumber25{
				.box{
					width: calc(  100% - 10px ) / 5 ;
					height: calc(  100% - 10px ) / 5 ;
				}
			}
			.UserBoxNumber36{
				.box{
					width: calc(  100% - 12px ) / 6 ;
					height: calc(  100% - 12px ) / 6 ;
				}
			}
			.UserBoxNumber49{
				.box{
					width: calc(  100% - 14px ) / 7 ;
					height: calc(  100% - 14px ) / 7 ;
				}
			}
			.UserBoxNumber56{
				.box{
					width: calc(  100% - 14px ) / 7 ;
					height: calc( 100% - 16px ) / 8 ;
				}
			}

			#UserBoxNumber{
				height: 100%; display: flex;
				justify-content: flex-start; // 靠左对齐
				flex-wrap: wrap; // 换行
				.box{
					margin: 1px;
					background-color: #6d737b;
				}
			}


			#bottomBtns{
				position: absolute; align-items: center; gap: 40px;
				bottom: 0;left: 0; height: 80px; justify-content: center;
				width: 100%; display: flex;z-index: 3;

				.btn{
					width:55px;height:55px;  border-radius:50%;
					font-size:30px; background: #FFF; color:#666;
					box-shadow: 1px 1px 3px 1px #000; position: relative;

					.delete {
						width: 3px;
						height: 30px;
						transform: skew(-45deg);left: 25.5px;top:12.5px;
						background-color: #333; position: absolute;
					}

					i{
						margin:12.5px;
					}
				}
				.close{
					background:#f45454;color:#FFF;
				}
				.open{
					background:#4caf50;color:#FFF;
				}
			}
		}
		#invite{

			#roomUserList{
				overflow: auto;
				.roomUser{
					overflow: hidden; margin: 10px; float: left;
					img{
						width: 40px; height:40px;
						float: left; border-radius: 50%;
					}
					.nickName{
						height:40px; line-height: 40px; margin-left: 10px;
						float: left;
					}
					.btn{
						height: 20px; line-height: 20px; width: 80px; float: left;
						margin: 10px; border: 1px solid #031f88; text-align: center;
						border-radius: 10px; color: #031f88;
					}
				}
			}

		}
	}
</style>
