<template>
    <div style="width: 1200px; height: 680px; border-radius: 12px; background: #4C9FF2; position: relative;">
        <!--顶部-->
        <div style="width: 100%; height: 120px; display: flex; border-top-left-radius: 12px; border-top-right-radius: 12px;">
            <div style="width: 420px; height: 100%; border-top-left-radius: 12px;" class="come">
                <div class="vip" style="width: 120px; height: 116px; position: relative; display: flex; justify-content: center; align-items: center; border-top-left-radius: 12px;">
                    <div style="width: 160px;line-height: 20px;margin-top: -40px;color: #FFFFFF;font-size: 20px;font-weight: 500;margin-left: 25px;">支付结果</div>
                </div>
            </div>
            <div style="width: 420px; height: 120px; margin-left: 360px;border-radius: 8px;">
                <div class="bgRight" style="width: 100%; height: 120px;border-radius: 8px;"></div>
            </div>
        </div>
        <div style="width: 1120px; height: 560px; background: #FFFFFF; position: absolute; top: 60px; left: 40px;border-radius: 8px;display: flex;text-align: left;">
           <div style="width: 300px;height: auto;margin: 0 auto;margin-top: 130px;">
               <el-col >
                   <el-result icon="success" title="支付成功" subTitle="请根据提示点击完成">
                       <template slot="extra">
                           <el-button @click="submit" type="primary" size="medium">完成</el-button>
                       </template>
                   </el-result>
               </el-col>
           </div>
        </div>
    </div>

</template>

<script>
export default {
    data() {
        return {
        }
    },
    mounted() {

    },
    methods:{
        submit() {
            window.location.reload();
        }
    }
};
</script>

<style scoped>
.come{
    background-image: url('../../../../assets/imgs/vip/header_left.png');
    background-size: 100% 100%;
}
.vip{
    background-image: url('../../../../assets/imgs/vip/header_diamond_success.png');
    background-size: 100% 100%;
}
.el-dialog__header{
    padding: 0px;
}
.el-dialog__body {
    padding: 0px;
}
.bgRight {
    background-image: url('../../../../assets/imgs/vip/header_right_success.png');
    background-size: 100% 100%;
}
ul{
    list-style: none;
}
.detailWrap li {
    width: 100%;
    max-width: 360px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
}
.detailWrap li .left{
    color: rgb(93, 111, 143);
}
.detailWrap li .right{
    color: rgb(53, 68, 93);
    line-height: 20px;
}
.boundary {
    width: 1px;
    height: 480px;
    flex-shrink: 0;
    background: rgb(229, 229, 229);
    margin-top: 37px;
    margin-left: 80px;
}
</style>

<script setup>
import VueQr from "_vue-qr@2.5.0@vue-qr";
import {getYear} from "../../../../utils/utils";
</script>
