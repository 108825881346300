<template>
    <div class="payment-password-container">
        <div class="payment-password-content">
            <div class="password-header">请输入您要设置的安全密码</div>
            <div class="password-input">
                <el-input
                        placeholder="请输入安全密码"
                        type="password"
                        v-model="paylod"
                        :validate-event="true"
                        @input="handleInput('paylod')"
                        show-password
                ></el-input>
            </div>
            <div class="password-input">
                <el-input
                        placeholder="请再次确认安全密码"
                        type="password"
                        v-model="paynow"
                        :validate-event="true"
                        @input="handleInput('paynow')"
                        show-password
                ></el-input>
            </div>
            <div class="button-container">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" @click="step">完成设置</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                paylod: "",
                paynow: "",
            };
        },
        methods: {
            handleInput(field) {
                this[field] = this[field].slice(0, 6);
            },
            cancel() {
                this.$emit("change", false);
            },
            step() {
                if (this.paylod === "" || this.paylod.length < 6) {
                    this.utils.err("密码至少为6位字符");
                    return;
                }

                if (this.paynow !== this.paylod) {
                    this.utils.err("密码不一致");
                    return;
                }

                const params = { pwd: this.paynow };
                this.newApi.setBgbPayPwd(params).then((res) => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.setup = false;
                        this.$emit("change", false);
                    }
                });
            },
        },
    };
</script>

<style scoped>
    .payment-password-container {
        justify-content: center;
        align-items: center;
    }

    .payment-password-content {
        width: 80%;
        max-width: 400px;
        padding: 20px;
        border-radius: 8px;
        background-color: #ffffff;
    }

    .password-header {
        font-size: 16px;
        color: #444444;
    }

    .password-input {
        margin-top: 10px;
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
    }
</style>
