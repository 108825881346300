import { render, staticRenderFns } from "./addImageFileBaseForm.vue?vue&type=template&id=2b377e38&scoped=true"
import script from "./addImageFileBaseForm.vue?vue&type=script&lang=js"
export * from "./addImageFileBaseForm.vue?vue&type=script&lang=js"
import style0 from "./addImageFileBaseForm.vue?vue&type=style&index=0&id=2b377e38&prod&scoped=true&lang=css"
import style1 from "./addImageFileBaseForm.vue?vue&type=style&index=1&id=2b377e38&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b377e38",
  null
  
)

export default component.exports