<template>
	<div id="app">
		<!--    直接访问router设置的根目录-->
		<div class="body">
			<transition name="el-fade-in-linear" mode="out-in" >
				<router-view></router-view>
			</transition>
		</div>
    <Head  v-if="headerShow"></Head>


		<!--        新登录弹窗-->
		<LoginModule v-if="showLoginPopup"></LoginModule>

		<RoomNav v-if="roomShow == 1"></RoomNav>

		<!--        视频通话-->
		<VideoCall v-if="is_login == 1"></VideoCall>

    <!--        认证组件-->
        <AuthWindow v-if="showAuthPopup"></AuthWindow>

		<!--		导览组件-->
		<Guide></Guide>

		<!--    回到顶部-->
		<ToTop></ToTop>

		<!--        进度条-->
		<div class="shadow fade" style="position:fixed;top:110px;width:398px;background:#FFF;left:calc( 50% - 200px );padding:10px 0;border-radius:4px;z-index:2100;" v-if="upload_info.length > 0">
			<div v-for="(item,index) in upload_info" :key="index" style="padding:10px 50px; overflow:auto;" class="fade" >
				<div >
					<div style="text-align:center;margin:0 0 5px 0;font-size:20px;">{{item.file_name}}上传中</div>
					<el-progress :text-inside="true" :stroke-width="26" :percentage="item.percent"></el-progress>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LoginModule from "./components/common/LoginModule";
// import FaceTime from './components/common/FaceTime'
import utils from "@/utils/utils";
import Head from "@/components/common/Head.vue";
import VideoCall from "@/components/common/VideoCall.vue";
import store from "@/store";
import Guide from "@/components/common/Guide.vue";
import AuthWindow from "@/components/common/authentication/AuthWindow.vue";
import ToTop from "@/components/common/ToTop.vue";
import RoomNav from "@/pages/room/components/RoomNav.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
	name: 'App',
	components: {
		ToTop,
		AuthWindow,
		Guide,
		VideoCall,
		LoginModule,
        Head,
		RoomNav

	},
	watch:{
		is_login(value,oldvalue){
			if (value===1){
				this.createWebSocket();
			}
			if (value===0 && oldvalue===1){
                // 离开标记
                this.socketLeaveFlag = true;
				this.socketObj.close();
			}
		}
	},
	data(){
		return{
			// websocket相关
			socketObj: "", // websocket实例对象
			//心跳检测
			heartCheck: {
				vueThis: this, // vue实例
				timeout: 30000, // 超时时间
				timeoutObj: null, // 计时器对象——向后端发送心跳检测
				serverTimeoutObj: null, // 计时器对象——等待后端心跳检测的回复
				// 心跳检测重置
				reset: function () {
					clearTimeout(this.timeoutObj);
					clearTimeout(this.serverTimeoutObj);
					return this;
				},
				// 心跳检测启动
				start: function () {
					// eslint-disable-next-line no-unused-expressions
					this.timeoutObj && clearTimeout(this.timeoutObj);
					// eslint-disable-next-line no-unused-expressions
					this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
					this.timeoutObj = setTimeout(() => {
						// 这里向后端发送一个心跳检测，后端收到后，会返回一个心跳回复
						this.vueThis.socketObj.send("HeartBeat");
						this.serverTimeoutObj = setTimeout(() => {
							// 如果超过一定时间还没重置计时器，说明websocket与后端断开了
							console.log("未收到心跳检测回复，运行关闭指令");
							// 关闭WebSocket
							this.vueThis.socketObj.close();
						}, this.timeout);
					}, this.timeout);
				},
			},
			socketReconnectTimer: null, // 计时器对象——重连
			socketReconnectLock: false, // WebSocket重连的锁
			socketLeaveFlag: false, // 离开标记（解决 退出登录再登录 时出现的 多次相同推送 问题，出现的本质是多次建立了WebSocket连接）

		};
	},
	computed:{
		...mapGetters(['getShowLoginPopup','getShowAuthPopup']),
		showLoginPopup() {
			return this.getShowLoginPopup;
		},
		showAuthPopup() {
			return this.getShowAuthPopup;
		},
		//vuex定义的图片数组 进度条用
		upload_info() {
			return this.$store.state.upload_info
		},
		is_login() {
			return this.$store.state.is_login
		},
		headerShow() {
			return this.$store.state.headerShow
		},
		roomShow() {
			return this.$store.state.roomShow
		},
	},
	created() {

	},
	mounted() {
        var that= this;
		//项目运行首先检查token是否过期
		if (window.localStorage.getItem("token")!=null && window.localStorage.getItem("token")!=="" && window.localStorage.getItem("token")!==undefined) {
		this.$store.commit("get_user_info");
		}
		if (process.env.NODE_ENV!=="development"){
			document.oncontextmenu = new Function("event.returnValue=false");
			document.addEventListener("keydown", function (e) {
				if (e.key == "F12") {
					e.preventDefault(); // 如果按下键F12,阻止事件
				}
			});
		}
        setTimeout(function (){
            if(that.is_login == 1){
                that.createWebSocket();
            }
        },1000)
	},
	destroyed() {
		// 离开标记
		this.socketLeaveFlag = true;
		// 关闭WebSocket
        this.socketObj.close();
	},
	methods:{

		// websocket启动
		createWebSocket() {
			let webSocKetUrl = "";
			if(process.env.NODE_ENV == "development"){
				if(!utils.subUrlStr(this.$store.state.devApiUrl,1).includes("baigongbao")){
					webSocKetUrl = "ws://"+ utils.subUrlStr(this.$store.state.devApiUrl,2) +"/wss/";
				}else{
					webSocKetUrl = "wss://"+ utils.subUrlStr(this.$store.state.devApiUrl,1) +"/wss/";
				}
			}else if(process.env.NODE_ENV == "production"){
				webSocKetUrl = "wss://"+ utils.subUrlStr(process.env.VUE_APP_BASE_API,1) +"/wss/";
			}
			let webSocketLink = webSocKetUrl + localStorage.getItem("token") // webSocket地址
			//console.log(webSocketLink);
			try {
				if ("WebSocket" in window) {
					this.socketObj = new WebSocket(webSocketLink);
				}else {
					console.log("不支持WebSocket");
				}
				// websocket事件绑定
				this.socketEventBind();
			} catch (e) {
				console.log("catch" + e);
				// websocket重连
				this.socketReconnect();
			}
		},
		// websocket事件绑定
		socketEventBind() {
			// 连接成功建立的回调
			this.socketObj.onopen = this.onopenCallback;
			// 连接发生错误的回调
			this.socketObj.onerror = this.onerrorCallback;
			// 连接关闭的回调
			this.socketObj.onclose = this.oncloseCallback;
			// 向后端发送数据的回调
			this.socketObj.onsend = this.onsendCallback;
			// 接收到消息的回调
			this.socketObj.onmessage = this.getMessageCallback;

			//监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
			window.onbeforeunload = () => {
				this.socketObj.close();
			};
		},
		// websocket重连
		socketReconnect() {
			if (this.socketReconnectLock) {
				return;
			}
			this.socketReconnectLock = true;
			// eslint-disable-next-line no-unused-expressions
			this.socketReconnectTimer && clearTimeout(this.socketReconnectTimer);
			this.socketReconnectTimer = setTimeout(() => {
				console.log("WebSocket:重连中...");
				this.socketReconnectLock = false;
				// websocket启动
				this.createWebSocket();
			}, 10000);
		},
		// 连接成功建立的回调
		onopenCallback: function () {
			// console.log(event);
			console.log("WebSocket:已连接");
			// 心跳检测重置
			this.heartCheck.reset().start();
		},
		// 连接发生错误的回调
		onerrorCallback: function () {
			// console.log(event);
			console.log("WebSocket:发生错误");
			// websocket重连
			this.socketReconnect();
		},
		// 连接关闭的回调
		oncloseCallback: function () {
			// console.log(event);
			console.log("WebSocket:已关闭");
			// 心跳检测重置
			this.heartCheck.reset();
			if (!this.socketLeaveFlag) {
				// 没有离开——重连
				// websocket重连
				this.socketReconnect();
			}
		},
		// 向后端发送数据的回调
		onsendCallback: function () {
			console.log("WebSocket:发送信息给后端");
		},
		// 接收到消息的回调
		getMessageCallback: function (msg) {
			var that =this;
			if (msg.data.indexOf("HeartBeat") > -1) {
				// 心跳回复——心跳检测重置
				// 收到心跳检测回复就说明连接正常
				// console.log("收到心跳检测回复");
				// 心跳检测重置
				this.heartCheck.reset().start();
			} else {
				let data = JSON.parse(msg.data);
				console.log(that.utils.changeTimeType(new Date().getTime()) + "收到推送消息类型为" + data.Notice, data);
				// 相关处理
				switch (data.Notice) {
					case "LiveUserNumber":
						that.$EventBus.$emit('LiveUserNumber', data);
						break;
					case "LiveMsg":
						that.$EventBus.$emit('LiveMsg', data);
						break;
					case "LiveFinish":
						that.$EventBus.$emit('LiveFinish', data);
						break;
					case "videoCallAddUser":
						store.commit('setVideoCall', {
							userList: data.info.userList
						});
						break;
					case "videoCallUserExitRoom":
						store.commit('setVideoCall', {
							userList: data.info.userList
						});
						break;
					case "videoCallDestory":
						utils.toast("视频通话已结束");
						store.commit('closeVideoCall');
						break;
					case "videoCall":
						store.commit('setVideoCall', {
							status: 2,//待接听
							roomId: data.info.roomId,
							createTime: data.info.createTime,
							userList: data.info.userList
						});
						break;
					case "notice":
						this.$store.state.noticeCount ++;
						utils.toast(data.info.msg,data.info.url,data.info.isShow);
                        that.$EventBus.$emit('notice', data);
						break;
					case "msg":
						this.$store.state.msgCount ++;
						utils.toast("您有一条私信", data.info.url);
						break;
					case "toast":
						this.$store.state.noticeCount ++;
						utils.toast(data.info.user.nickName + data.info.msg,data.info.url,data.info.isShow);
						break;
					case "system":
						this.$store.state.systemCount ++;
						utils.notice(data.info.msg);
						break;
					case "face":
						that.$EventBus.$emit('faceData', data.info);
						break;
					case "facetime":
						that.$EventBus.$emit('facetime', data.info);
						break;
					case "room":
						that.$EventBus.$emit('room', data.info);
						utils.toast(data.info.content,data.info.url,data.info.isShow);
						break;
                    case "payNotice":
                        that.$EventBus.$emit('payMsg', data);
                        break;
                    case "payNotice":
                        that.$EventBus.$emit('payMsg', data);
						break;
                    case "callbackNotice":
                        that.$EventBus.$emit('callbackNotice', data);
                        break;
				}
			}
		},

	},
};
</script>

<style>
*{
	margin: 0;
	padding: 0;
}
#app {
}
.body{
	min-width: 1440px;margin:0;
	padding:0;
	/*min-height:1080px;*/
	overflow:auto;
	background:#f0f0f0;
}
/*视频播放按钮的位置*/
.vjs-big-play-button {
	top: 50% !important;
	left: 50% !important;
	transform:translate(-50%,-50%);
}
.hand{
	cursor:pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.op:active{
	opacity:0.6;
	-webkit-transition:all .1s;transition:all .1s;opacity:.6;
}
.custom-cursor-style {
    cursor: pointer;
}
</style>
