import Vue from 'vue';
import App from './App.vue';
// 插件css
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/base.css';
import 'video.js/dist/video-js.css';
import 'nprogress/nprogress.css';
import 'vue-photo-preview/dist/skin.css';
import './assets/css/common/app.css';
// 插件
import ElementUI from 'element-ui';
import VideoPlayer from 'vue-video-player';
import NProgress from 'nprogress';
import preview from 'vue-photo-preview';
import vueQr from 'vue-qr';
import router from "./router/index";
import $ from 'jquery';
import utils from "./utils/utils";
import store from './store';
import newApi from './utils/newApi';
import designer from './components/common/formDesigner/index';
import message from './utils/resetMessage';
import * as echarts from 'echarts'
import elTableInfiniteScroll from 'el-table-infinite-scroll';

Vue.use(elTableInfiniteScroll);

var ossUrl = "https://oss.baigongbao.com/";
var loading;
// 配置NProgress进度条选项 —— 动画效果
NProgress.configure({ease: 'speed', speed: 500})

Vue.use(designer);

Vue.config.productionTip = false;
Vue.prototype.ossUrl = ossUrl;
Vue.prototype.$ = $;
Vue.prototype.newApi = newApi;
Vue.prototype.utils = utils;
Vue.prototype.$EventBus = new Vue();
Vue.prototype.$echarts = echarts;

//环境参数
if (process.env.NODE_ENV == "production") {
    store.state.devApiUrl = process.env.VUE_APP_BASE_API;
    console.log("当前环境==>：" + process.env.NODE_ENV + "==>Api地址==>：" + store.state.devApiUrl);
}

Vue.prototype.msg = function (content) {
    this.$message({
        message: content,
        type: 'warning'
    });
}

/**
 * 开始裁剪
 * @param width 裁剪框宽度
 * @param height 裁剪框高度
 * @param imgUrl 需裁减的图片地址
 */

// 自定义监听sessionStorage的方法
Vue.prototype.$addStorageEvent = function (key, data) {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
        setItem: function (k, val) {
            sessionStorage.setItem(k, val);
            // 初始化创建的事件
            newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
            // 派发对象
            window.dispatchEvent(newStorageEvent);
        }
    }
    return storage.setItem(key, data);
}

Vue.prototype.urlType = function () {
    return utils.getUrlType();
}

Vue.prototype.urlType = function () {
    return utils.getUrlType();
}

Vue.prototype.a = function (url,refresh = 0) {
	utils.a(url,refresh);
}

Vue.prototype.b = function (url) {
    utils.b(url);
}

Vue.prototype.openLoading = function (msg) {
    loading = utils.loading(msg);
    return loading;
}

Vue.prototype.closeLoading = function () {
    if (typeof loading != "undefined") {
        loading.close();
    }
    loading = undefined;
}

Vue.use(VideoPlayer);
Vue.use(ElementUI);
Vue.use(preview);
Vue.use(vueQr);
Vue.prototype.cropCallBack = null;
Vue.prototype.$message = message;
/**
 * 裁剪方法 样例在 /test/cropperExample.vue
 * @param width 需裁剪为宽度
 * @param height 需裁剪为高度
 * @param img 原图
 * @param callBack 回调方法
 */
Vue.prototype.crop = function (width, height, img, callBack) {
    store.commit('setCropper', {
        active: true,
        width: width,
        height: height,
        img: img
    });
    Vue.prototype.cropCallBack = callBack;
}

Vue.prototype.videoCall = function (uuid, roomId) {
    //校验登录
    if (store.state.is_login === 0) {
        utils.err("你还未登录");
        return;
    }
    
    var uuidList = [];
    
    if (typeof (uuid) == 'string') {
		// console.log("单人");
		// //单人
        uuidList.push(uuid);
    }
    if (uuid instanceof Array) {
		// console.log("多人");
        //多人
        uuidList = uuid;
    }
    
    //至少有一个在线的人
    newApi.hasOneOnline({
        uuidList: JSON.stringify(uuidList)
    }).then((ret) => {
		// console.log("hasOneOnline",ret)
        if (ret.data == "不在在线") {
            utils.err("对方不在线");
            return;
        }
        uuidList.unshift(store.state.user.uuid);//加入自己的uuid
        newApi.makeFaceTime({
            ids: JSON.stringify(uuidList)
        }).then((ret) => {
            store.commit('setVideoCall', {
                status: 1,
                roomId: ret.data.roomId,
                id: roomId,
                createTime: ret.data.createTime,
                userList: ret.data.userList
            });
        })
    })
}

Vue.prototype.cropCallBack = null;
Vue.prototype.crop = function (width, height, img, callBack) {
    store.commit('setCropper', {
        active: true,
        width: width,
        height: height,
        img: img
    });
    Vue.prototype.cropCallBack = callBack;
}
Vue.prototype.guide = function (stepList) {
    store.commit('setGuideData', {
        active: true,
        stepList: stepList
    })
}


var _hmt = _hmt || [];
(function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?7605b3d1de39144df52624b3edf61b8e";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();



new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app');

