import Vue from "vue";
import VueRouter from 'vue-router'

Vue.use(VueRouter);

let routes =
    [
        {
            path:'/filebase/index',   //知识仓库
            component: () => import('../pages/filebase/index'),
            meta:{
                title:"知识仓库",
                showHeader:true
            }
        },
        {
            path:'/filebase/more/:file_type',   //知识仓库类型详情页
            component: () => import('../pages/filebase/more'),
            meta:{
                title:"知识仓库more",
                showHeader: true
            }
        },
        {
            path:'/shop/index/',   //新店铺商城
            component: () => import('../pages/shop/index'),
            meta:{
                title:"新店铺商城",
                showHeader:true,
            }
        },
        {
            path:'/content/add/:id',   //发布文章
            name: 'contentAdd',
            component: () => import('../pages/content/add'),
            meta:{
                title:"发布文章",
                auth:['login','realName']
            }
        },
        {
            path:'/index/reg/',   //注册
            name: 'reg',
            component: () => import('../components/common/reg.vue'),
            meta:{
                title:"注册"
            }
        },
        {
            path:'/user/index/:id',   //钱包
            component: () => import('../pages/user/index.vue'),
            meta:{
                title:"我的钱包",
                auth:['login','realName']
            }
        },
        {
            path:'/safety',   // 安全中心
            component: () => import('../pages/index/safety.vue'),
            meta:{
                title:"安全中心",
                auth:['login']
            }
        },
        {
            path:'/content/info/:id/:type',   //内容
            component: () => import('../pages/content/info'),
            meta:{
                title:"文章详情页",
            }
        },
        {
            path:'/filebase/upload/:id',   //上传资料
            component: () => import('../pages/filebase/upload'),
            meta:{
                title:"上传资料",
                auth:['login','realName']
            }
        },
        {
            path:'/content/edit/:id',   //文章编辑
            name:"content_edit",
            component: () => import('../pages/content/edit'),
            meta:{
                title:"文章编辑",
                auth:['login','realName']
            }
        },
        {
            path:'/filebase/fileinfo/:fid',  //知识仓库分类详情页
            component: () => import('../pages/filebase/fileinfo'),
            meta:{
                need_login:false,
                title:"知识仓库分类详情页",
                showHeader: true
            }
        },
        {
            path:'/content/picture/:id',  //图片详情页
            component: () => import('../pages/content/picture'),
            meta:{
                need_login:false,
                title:"图片详情页"
            }
        },
        {
            path:'/index/contentmore/',  //推荐频道
            component: () => import('../pages/index/contentmore'),
            props: route => ({
                class_id: route.query.class_id,
                type: route.query.type,
            }),
            meta:{
                showHeader: true,
                title:"推荐频道"
            }
        },
        {
            path:'/shop/moreService/',   //更多服务
            component: () => import('../pages/shop/moreService'),
            meta:{
                title:"更多服务",
                showHeader: true,
            }
        },
        {
            path:'/index/page',   //红包问答详情页
            component: () => import('../pages/index/page'),
            meta:{
                title:"详情页"
            }
        },
        {
            path: '/index/details/:id',   //商务信息详情页
            component: () => import('../pages/index/details'),
            meta: {
                title: "详情页"
            }
        },
        {
            path:'/form/diy',  //创建模板
            name: 'Diy',
            component: () => import('../pages/form/diy'),
            meta:{
                title:"创建模板",
            }
        },{
            path:'/form/view',  //模板
            name: 'View',
            component: () => import('../pages/form/view'),
            meta:{
                title:"模板",
            }
        },
        {
            path:'/cityDemo',  //城市选择demo
            component: () => import('../pages/test/cityDemo'),
            meta:{
                title:"城市选择Demo",
            }
        },
        {
            path:'/shop/serVice/',  //服务商入住
            component: () => import('../pages/shop/serVice'),
            meta:{
                title:"服务商入住",
                auth:['login','realName']
            }
        },
        {
            path:'/shop/shopCode/',  //店铺
            component: () => import('../pages/shop/shopCode'),
            meta:{
                title:"店铺",
                showHeader: true,
            }
        },
        {
            path:'/shop/addGoods/',  //发布服务
            component: () => import('../pages/shop/addGoods'),
            meta:{
                title:"发布服务",
            }
        },
        {
            path:'/shop/product/',  //发布产品设备
            component: () => import('../pages/shop/product'),
            meta:{
                title:"发布产品设备",
            }
        },
        {
            path:'/aliCodeUrl/:orderNo',  //阿里支付新页面二维码
            component: () => import('../components/common/Pay/aliPay/aliCodeUrl.vue'),
            meta:{
                title:"阿里支付",
            }
        },
        {
            path:'/shop/shopBackstage/',  //商品后台
            component: () => import('../pages/shop/shopBackstage'),
            meta:{
                title:"商品后台",
            }
        },
        {
            path:'/toPay/:orderNo',  //企业支付
            component: () => import('../components/common/Pay/organ/toPay.vue'),
            meta:{
                title:"企业支付",
            }
        },
        {
            path:'/shop/serviceInfo/:id',  //服务详情
            component: () => import('../pages/shop/service/serviceInfo'),
            meta:{
                title:"服务详情",
                showHeader: true,
            }
        },
        {
            path:'/shop/contractInfo/',  //合同详情
            component: () => import('../pages/shop/service/contractInfo'),
            meta:{
                title:"合同详情",
            }
        },
        {
            path:'/shop/serviceVerify/:id',  //服务订单
            component: () => import('../pages/shop/serviceVerify'),
            meta:{
                title:"服务订单",
            }
        },
        {
            path:'/index/platformLandingPage/',  //平台着陆页
            component: () => import('../pages/index/platformLandingPage'),
            meta:{
                title:"平台着陆页",
                showHeader: true
            }
        },
        {
            path:'/index/expertLinkLandingPage/',  //专家连线着陆页
            component: () => import('../pages/index/expertLinkLandingPage'),
            meta:{
                title:"专家连线着陆页",
                showHeader: true
            }
        },
        {
            path:'/index/warehouseLandingPage/',  //知识仓库着陆页
            component: () => import('../pages/index/warehouseLandingPage'),
            meta:{
                title:"知识仓库着陆页",
                showHeader:true
            }
        },
        {
            path:'/index/momentsLandingPage/',  //朋友圈着陆页
            component: () => import('../pages/index/momentsLandingPage'),
            meta:{
                title:"朋友圈着陆页",
                showHeader: true
            }
        },
        {
            path:'/index/roomLandingPage/',  //ROOM着陆页
            component: () => import('../pages/index/roomLandingPage'),
            meta:{
                title:"ROOM着陆页",
                showHeader: true
            }
        },
        {
            path:'/index/shopLandingPage/',  //SHOP着陆页
            component: () => import('../pages/index/shopLandingPage'),
            meta:{
                title:"SHOP着陆页",
                showHeader: true
            }
        },
        {
            path:'/shop/order/',  // 我的订单
            component: () => import('../pages/shop/order'),
            meta:{
                title:"我的订单",
                need_login: true,
            }
        },
        {
            path:'/shop/orderInfo/:id',  // 订单详情
            component: () => import('../pages/shop/service/orderInfo'),
            meta:{
                title:"订单详情",
            }
        },
        {
            path:'/shop/addCase/',  // 发布案例
            component: () => import('../pages/shop/addCase'),
            meta:{
                title:"发布案例",
            }
        },
        {
            path:'/index/sevenLandingPage/',  //着陆页
            component: () => import('../pages/index/sevenLandingPage'),
            meta:{
                title:"着陆页",
                showHeader: true
            }
        },
        {
            path:'/shop/serviceDetails/:id',  //服务详情页
            component: () => import('../pages/shop/serviceDetails'),
            meta:{
                title:"服务详情页",
                showHeader: true,
            }
        },
        {
            path:'/shop/productDetails/:id',  //产品详情页
            component: () => import('../pages/shop/productDetails'),
            meta:{
                title:"产品详情页",
                showHeader: true,
            }
        },
        {
            path:'/shop/cart/',  //购物车
            component: () => import('../pages/shop/cart/index'),
            meta:{
                title:"购物车",
                showHeader: true,
            }
        },
        {
            path:'/shop/afterSale/',  // 售后详情
            name:'AfterSale',
            component: () => import('../pages/shop/afterSale'),
            meta:{
                title:"售后详情",
            }
        },
        {
            path:'/index/news/',  // 更多今日头条
            component: () => import('../pages/index/news'),
            meta:{
                title:"更多今日头条",
                showHeader: true
            }
        },
        {
            path:'/shop/moreProduct/',   //更多商品
            component: () => import('../pages/shop/moreProduct'),
            meta:{
                title:"更多商品",
                showHeader: true,
            }
        },
        {
            path:'/shop/moreShop/',   //更多店铺
            component: () => import('../pages/shop/moreShop'),
            meta:
                {
                    title:"更多店铺",
                    showHeader: true,
                }
        },
        {
            path: '/wxBind',   //微信登录扫码
            component: () => import('../pages/index/wxBind'),
            props: route => ({
                code: route.query.code,
            }),
            meta:{
                title: "微信扫码登陆"
            }
        },
        {
            path: '/qqBind',   //qq登录扫码
            component: () => import('../pages/index/qqBind'),
            props: route => ({
                code: route.query.code,
            }),
            meta:{
                title: "QQ扫码登陆"
            }
        },
        {
            path:'/shop/createOrder',  // 生成订单
            name: 'CreateOrder',
            component: () => import('../pages/shop/product/createOrder'),
            meta:{
                title:"生成产品订单",
                showHeader: true,
            }
        },
        {
            path:'/shop/cartCreateOrder',  // 购物车生成订单
            name: 'CartCreateOrder',
            component: () => import('../pages/shop/product/cartCreateOrder'),
            meta:{
                title:"生成产品订单",
                showHeader: true,
            }
        },
        {
            path:'/shop/caseDetailsPage/:id',  //案例详情页
            name: 'CaseDetailsPage',
            component: () => import('../pages/shop/caseDetailsPage'),
            meta:{
                title:"案例详情页",
                showHeader: true,
            }
        },
        {
            path:'/content/headlines/:id',  //头条编辑
            name: 'headlines',
            component: () => import('../pages/content/headlines'),
            meta:{
                title:"头条编辑",
                auth:['login','realName']
            }
        },
        {
            path:'/tempLate/templateInformationMore/',  //模板信息MORE
            name: 'headlines',
            component: () => import('../components/common/tempLate/templateInformationMore'),
            meta:{
                title:"更多模板信息",
            }
        },
        {
            path:'/index/moreQuestion/',  //更多红包问答
            name: 'headlines',
            component: () => import('../pages/index/moreQuestion'),
            meta:{
                title:"更多红包问答",
            }
        },
        {
            path:'/index/filingCabinet/',  //我的文件柜
            name: 'headlines',
            component: () => import('../pages/index/filingCabinet'),
            meta:{
                title:"我的文件柜",
                auth:['login','realName']
            }
        },
        {
            path:'/test/wordLink',   //知识仓库
            component: () => import('../pages/test/wordLink'),
            meta:{
                title:"知识仓库",
                showHeader:true,
            }
        },
        {
            path:'/member',   //会员中心
            component: () => import('../pages/user/member/index.vue'),
            meta:{
                title:"会员中心",
                auth:['login','realName']
            }
        },
        {
            path:'/index/notice',   //通知公告
            component: () => import('../pages/index/notice'),
            meta:{
                title:"通知公告",
                showHeader:true,
            }
        },
        {
            path:'/orderNoList',   //我的订单
            name: 'orderNoList',
            component: () => import('../components/common/Pay/orderNoList.vue'),
            meta:{
                title:"我的订单",
                auth:['login']
            },
        },
        {
            path:'/vipPage',   //开通会员
            component: () => import('../components/common/Pay/vip/vipPage.vue'),
            meta:{
                title:"通知公告",
                showHeader:false,
            }
        },
        {
            path:'/demandhall/index',   //需求大厅
            component: () => import('../pages/demandhall/index'),
            meta:{
                title:"需求大厅",
                showHeader:true,
            }
        },
        {
            path:'/askaquestions/index',   //红包问答
            component: () => import('../pages/askaquestions/index'),
            meta:{
                title:"红包问答",
                showHeader:true,
            }
        },

    ]

export default routes



