<template>
    <div id="head" style="width: 100%;height: 101px;background: #FFFFFF;z-index: 10;position: fixed;top: 0px;box-shadow: 0 3px 5px #a6a6a6;">
        <div style="width: 1500px;height:50px;margin-left: calc((100% - 1500px) / 2);">
            <div style="width:160px;height:50px;float: left;">
                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bai_log.png" style="width: 100%;height: 100%;cursor: pointer" @click="add_return">
            </div>
            <div style="width: calc(100% - 160px);height: 50px;overflow: hidden;">
                <div style="width: 100%;height: 100%;">
                    <div style="width: 70%;height: 100%;float: left;">
                    </div>
                    <div style="width: 30%;height: 50px;margin:0 auto ;float: left;">
                        <div style="width: 180px;line-height: 50px;margin: 0 10px;text-indent: 50px;text-align: center" v-if="is_login == 0">
                            <span @click='NeedLogin()' style="color: red;cursor: pointer;font-size: 16px;">登录</span>
                            <span> | </span>
                            <span @click="reg" style="color:#a6a6a6;cursor: pointer;font-size: 16px;">注册</span>
                        </div>
                        <div style="height:50px;width: 330px;margin: 0 auto;" v-if="is_login == 1">
                            <!--   登录名称    -->
                            <div style="width: auto;float: right">
                                <el-dropdown @command="login_out" >
                                    <div class="hand op sc" style="width: 100%;height: auto;display: flex;text-align: right">
                                        <img :src="user.headIco" style="width: 26px;height: 26px;margin:12px 0;border-radius: 50%;">
                                        <div style="color: #031F88;height: 50px;line-height: 50px;font-size: 15px;max-width: 210px">{{ user.nickName }}</div>
                                        <div style="line-height: 50px;"><i class="el-icon-caret-bottom el-icon--right" style="color: #031F88 "></i></div>
                                    </div>
                                    <!--  下拉菜单 -->
                                    <el-dropdown-menu slot="dropdown" style="margin-top: -5px;">
                                        <div style="width: 130px;min-height: 180px;background: #FFFFFF;">
                                            <div v-if="user.userType != 3" style="display: flex;justify-content: center">
                                                <div class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="circle">
                                                    <div style="width: 15px;height: 16px;">
                                                        <img src="~@/assets/imgs/index/self1.png" style="width: 15px;height: 16px;">
                                                    </div>
                                                    <div v-if="user.userType == 1" style="height: 19px;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10.5px;">
                                                        我的主页
                                                    </div>
                                                    <div v-if="user.userType == 2" style="height: 19px;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10.5px;">
                                                        机构主页
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user.userType == 3" style="display: flex;justify-content: center">
                                                <div class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="circle">
                                                    <div style="width: 15px;height: 16px;">
                                                        <img src="~@/assets/imgs/index/self1.png" style="width: 15px;height: 16px;">
                                                    </div>
                                                    <div  style="height: 19px;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10.5px;">
                                                        子空间
                                                    </div>
                                                </div>
                                            </div>
                                            <div  v-if="user.userType == 1" style="display: flex;justify-content: center">
                                                <div class="hand op sc" style="height: 36px;width:114px;display: flex;justify-content: center;align-items: center;" @click="toAuth">
                                                    <div style="width: 15px;height: 16px;">
                                                        <img src="~@/assets/imgs/index/self2.png" style="width: 15px;height: 16px;">
                                                    </div>
                                                    <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10.5px;">
                                                        个人档案
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user.userType == 2" style="display: flex;justify-content: center">
                                                <div class="hand op sc" @click="a('/auth')" style="height: 36px;width:114px;display: flex;justify-content: center;align-items: center;">
                                                    <div style="width: 15px;height: 16px;">
                                                        <img src="~@/assets/imgs/index/self2.png" style="width: 15px;height: 16px;">
                                                    </div>
                                                    <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10.5px;">
                                                        机构认证
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user.userType != 3" style="display: flex;justify-content: center">
                                                <div class="hand op sc" style="height:36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="a('/vipPage')">
                                                    <div style="width: 18px;height: 17px;">
                                                        <img src="~@/assets/imgs/index/self4.png" style="width: 18px;height: 17px;">
                                                    </div>
                                                    <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10px;">
                                                        会员中心
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display: flex;justify-content: center">
                                                <div v-if="user.isVip > 0" class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="isSpaceSizeWin = true">
                                                    <div style="width: 18px;height: 17px;">
                                                        <img src="~@/assets/imgs/index/self4.png" style="width: 18px;height: 17px;">
                                                    </div>
                                                    <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10px;">
                                                        空间扩容
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display: flex;justify-content: center">
                                                <div v-if="user.userType == 2 && user.isVip > 0" class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="isSubAccountWin = true">
                                                    <div style="width: 18px;height: 17px;">
                                                        <img src="~@/assets/imgs/index/self4.png" style="width: 18px;height: 17px;">
                                                    </div>
                                                    <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10px;">
                                                        席位扩容
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user.userType != 3" style="display: flex;justify-content: center" @click="a('/safety')">
                                                <div class="hand op sc" style="height: 36px;width:114px;display: flex;justify-content: center;align-items: center;" >
                                                    <div style="height: 22px;width: 22px;">
                                                        <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/setup.png" style="height: 22px;width: 22px;">
                                                    </div>
                                                    <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 8px;">
                                                        安全中心
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display: flex;justify-content: center">
                                                <div class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="a('/index/ruleCenter/' + 0)">
                                                    <div style="width: 15px;height: 16px;">
                                                        <img src="~@/assets/imgs/index/self5.png" style="width: 15px;height: 16px;">
                                                    </div>
                                                    <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 11px;">
                                                        规则中心
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display: flex;justify-content: center">
                                                <div class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="a('/index/heIp/' + 0)">
                                                    <div style="width: 15px;height: 16px;">
                                                        <img src="~@/assets/imgs/index/self6.png" style="width: 15px;height: 16px;">
                                                    </div>
                                                    <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 11px;">
                                                        帮助中心
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display: flex;justify-content: center">
                                                <div class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;position: relative" @click="switchAccounts">
                                                    <div style="width: 15px;height: 15px;">
                                                        <img src="~@/assets/imgs/index/qhzh.png" style="width: 15px;height: 15px;">
                                                    </div>
                                                    <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 11px;">
                                                        切换账号
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="position: relative;display: flex;justify-content: center">
                                                <div style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;">
                                                    <el-dropdown-item>退出登录</el-dropdown-item>
                                                </div>
                                            </div>
                                        </div>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <div style="width: 40px;height: 20px;margin:18px 10px  12px 20px;float:right;" @click="message">
                                <el-badge :hidden="$store.state.systemCount==0" :max="99" :value="$store.state.systemCount" class="item" >
                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/icon.png" style="width: 25px;height: 19px;">
                                </el-badge>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: calc(((100% - 1500px) / 2) + 1500px);margin-bottom:5px;height:46px;margin-left:calc((100% - 1500px) / 2);background-color:#031f88;border-top-left-radius: 80px;">
            <div style="padding-left: 150px;width: 1350px;display: flex;height: 46px;line-height: 46px;" >
                <div id="guide_01_ROOM"  class="tab border_bottom up" @click="set_route('/')">
                    <div class="head_white">
                        <div class="head_white_word" :class="{active:( routes==='/' || routes==='room')}">ROOM</div>
                    </div>
                </div>
                <div id="guide_01_ZHISHICHANGKU" class="tab border_bottom up" @click="set_route('/filebase/index')">
                    <div class="head_white">
                        <div class="head_white_word" :class="{active: routes==='filebase'}">知识仓库</div>
                    </div>
                </div>
                <div id="guide_01_EXPERT" class="tab border_bottom up" @click="set_route('/expert/index')">
                    <div class="head_white">
                        <div class="head_white_word" :class="{active: routes==='expert'}">专家连线</div>
                    </div>
                </div>
                <div id="guide_01_PENGYOUQUAN" class="tab border_bottom up" @click="set_route('/circle/index')" >
                    <div class="head_white">
                        <div class="head_white_word" :class="{active: routes==='circle'}">朋友圈</div>
                    </div>
                </div>
                <div id="guide_01_HONGBAOWENDA" class="tab border_bottom up" @click="set_route('/askaquestions/index')">
                    <div class="head_white">
                        <div class="head_white_word" :class="{active: routes==='askaquestions'}">红包问答</div>
                    </div>
                </div>
                <div id="guide_01_XUQIUDATING" class="tab border_bottom up" @click="set_route('/demandhall/index')">
                    <div class="head_white">
                        <div class="head_white_word" :class="{active: routes==='demandhall'}">需求大厅</div>
                    </div>
                </div>

                <div id="guide_01_GONGGONGYE"  class="tab border_bottom up" @click="set_route('/index')">
                    <div class="head_white">
                        <div class="head_white_word" :class="{active:routes=='index'}">行业资讯</div>
                    </div>
                </div>

<!--				<div id="guide_01_SHOP" class="tab border_bottom up" @click="set_route('/shop/index')" >-->
<!--					<div class="head_white">-->
<!--						<div class="head_white_word" :class="{active: routes==='shop'}">SHOP</div>-->
<!--					</div>-->
<!--				</div>-->


                <div  id="guide_01_MENU"  class="tab border_bottom up" style="width:100%;margin: 0;"  v-if="is_login === 1">
                    <div style="width: 70%;;height: 50px;line-height:50px;text-align: right;float: right">
                        <el-dropdown  trigger="click" ref = "eldropdewn">
                            <div style="font-weight: bold;color: #FFFFFF;font-size: 16px;cursor: pointer"  v-on:mouseover = "mouseover()" >MENU<svg t="1691828043729" class="icon" viewBox="0 0 1316 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4170" width="20" height="13"><path d="M0 0h1316.571429v146.285714H0zM0 438.857143h1316.571429v146.285714H0zM0 877.714286h1316.571429v146.285714H0z" p-id="4171" fill="#ffffff"></path></svg></div>
                            <el-dropdown-menu slot="dropdown" style="margin-top: -5px;">
                                <div style="display: flex;">
                                    <!--  菜单左侧 -->
                                    <div style="width: 230px; background-color: #FBFBFB;margin-left: 7px;">
                                        <!--   我的百工宝   -->
                                        <div style="width: 8px;height: 22px;background: #031F88;float: left"></div>
                                        <div style="width: 500px;height: 30px;line-height: 25px;font-weight: bold;font-size: 18px;color: #031F88;margin-left: 20px;">我的百工宝</div>
                                        <div style="height: auto;width: 90%;margin: 0 auto;box-sizing: content-box;">
                                            <!--  我的日历 -->
                                            <div  class="hand op sc menu" style="height: 40px;overflow:hidden;width: 100%;" @click="showCalendar=true">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="~@/assets/imgs/index/head7.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div style="height: auto;width: calc(100% - 46px);float: left;line-height: 40px;font-size: 15px;cursor: pointer;font-weight: bold;">
                                                    我的日历
                                                </div>
                                            </div>
                                            <!--   我的钱包-->
                                            <div v-if="user.userType < 3" class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="getMywallet">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="~@/assets/imgs/index/head1.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div style="height: auto;overflow: hidden;width: calc(100% - 46px);float: left;line-height: 40px;font-size: 15px;cursor: pointer;font-weight: bold;">
                                                    我的钱包
                                                </div>
                                            </div>
                                            <!--    空间容量 -->
                                            <div   class="hand op sc menu" style="height: 40px;overflow:hidden;width: 100%;" @click="circleType(space)">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="~@/assets/imgs/index/head2.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div style="height: auto;width: calc(100% - 46px);float: left;line-height: 40px;font-size: 15px;cursor: pointer;font-weight: bold;">
                                                    我的空间容量
                                                </div>
                                            </div>
                                        </div>
                                        <!--   个人专家中心    -->
                                        <div v-if="user.userType == 1">
                                            <div style="width: 8px;height: 22px;background: #031F88;float: left"></div>
                                            <div style="width: 500px;height: 30px;line-height: 25px;font-weight: bold;font-size: 18px;color: #031F88;margin-left: 20px;margin-top: 10px;">专家中心</div>
                                            <div style="height: auto;width: 90%;margin: 0 auto;box-sizing: content-box;">
                                                <div v-if="user.expertAuthStatus != 2" class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="addbecome">
                                                    <div style="height: 25px;width: 25px;padding: 8px 12px 3px 2px;float: left;">
                                                        <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/menu10.png" style="height: 25px;width: 25px;">
                                                    </div>
                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                        成为专家
                                                    </div>
                                                </div>
                                                <div v-else class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="expert">
                                                    <div style="height: 25px;width: 25px;padding: 8px 12px 3px 2px;float: left;">
                                                        <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/menu9.png" style="height: 25px;width: 25px;">
                                                    </div>
                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                        专家空间
                                                    </div>
                                                </div>
                                                <div class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="b('/expert/list/')">
                                                    <div style="height: 25px;width: 25px;padding: 8px 12px 3px 2px;float: left;">
                                                        <img src="~@/assets/imgs/index/head7.png" style="height: 25px;width: 25px;">
                                                    </div>
                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                        咨询订单
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--   机构功能  -->
                                        <div v-if="user.userType == 2">
                                            <div style="width: 8px;height: 22px;background: #031F88;float: left"></div>
                                            <div style="width: 500px;height: 30px;line-height: 25px;font-weight: bold;font-size: 18px;color: #031F88;margin-left: 20px;margin-top: 10px;">机构功能</div>
                                            <div style="height: auto;width: 90%;margin: 0 auto;box-sizing: content-box;">
                                                <div class="hand op sc menu" style="height: auto;overflow: hidden;width: 100%;" @click="addSubaccount">
                                                    <div style="height: 25px;width: 25px;padding: 8px 12px 3px 2px;float: left;">
                                                        <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/menu9.png" style="height: 25px;width: 25px;">
                                                    </div>
                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                        机构子账号管理
                                                    </div>
                                                </div>
                                                <div  class="hand op sc menu" style="height: auto;overflow: hidden;width: 100%;" @click="addressbook">
                                                    <div style="height: 25px;width: 25px;padding: 8px 12px 3px 2px;float: left;">
                                                        <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/menu10.png" style="height: 25px;width: 25px;">
                                                    </div>
                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                        机构通讯录
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--  个人、机构我是买家 -->
                                        <div v-if="user.userType == 1 || user.userType == 2">
                                            <div style="width: 8px;height: 22px;background: #031F88;float: left;"></div>
                                            <div style="width: 500px;height: 30px;line-height: 25px;font-weight: bold;font-size: 18px;color: #031F88;margin-left: 20px;margin-top: 10px;">我是买家</div>
                                            <div style="height: auto;width: 90%;margin: 0 auto;box-sizing: content-box;">
                                                <!--                                            <div class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="a('/shop/cart')">-->
                                                <!--                                                <div style="height: 25px;width: 25px;padding: 8px 12px 3px 2px;float: left;">-->
                                                <!--                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/menu12.png" style="height: 25px;width: 25px;">-->
                                                <!--                                                </div>-->
                                                <!--                                                <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">-->
                                                <!--                                                    我的购物车-->
                                                <!--                                                </div>-->
                                                <!--                                            </div>-->
                                                <!--                                            <div  class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="a('/shop/order')">-->
                                                <!--                                                <div style="height: 25px;width: 25px;padding: 8px 12px 3px 2px;float: left;">-->
                                                <!--                                                    <img src="~@/assets/imgs/index/head7.png" style="height: 25px;width: 25px;">-->
                                                <!--                                                </div>-->
                                                <!--                                                <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">-->
                                                <!--                                                    我的订单-->
                                                <!--                                                </div>-->
                                                <!--                                            </div>-->
                                                <!--                                            <div class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="a('')">-->
                                                <!--                                                <div style="height: 25px;width: 25px;padding: 8px 12px 1px 2px;float: left;">-->
                                                <!--                                                    <img src="~@/assets/imgs/index/head8.png" style="height: 25px;width: 25px;">-->
                                                <!--                                                </div>-->
                                                <!--                                                <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">-->
                                                <!--                                                    我的合同-->
                                                <!--                                                </div>-->
                                                <!--                                            </div>-->
                                                <div class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="add_money(8)">
                                                    <div style="height: 25px;width: 25px;padding: 8px 12px 1px 2px;float: left;">
                                                        <img src="~@/assets/imgs/index/head9.png" style="height: 25px;width: 25px;">
                                                    </div>
                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                        我的发票
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  子账号机构功能 -->
                                        <div v-if="user.userType == 3">
                                            <div style="width: 8px;height: 22px;background: #031F88;float: left;"></div>
                                            <div style="width: 500px;height: 30px;line-height: 25px;font-weight: bold;font-size: 18px;color: #031F88;margin-left: 20px;margin-top: 10px;">机构功能</div>
                                            <div style="height: auto;width: 90%;margin: 0 auto;box-sizing: content-box;">
                                                <div class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="a('/circle/organSpace/' + user.fUuid)">
                                                    <div style="height: 25px;width: 25px;padding: 8px 12px 3px 2px;float: left;">
                                                        <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/menu14.png" style="height: 25px;width: 25px;">
                                                    </div>
                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                        机构主页
                                                    </div>
                                                </div>
                                                <div  class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="addressbook">
                                                    <div style="height: 25px;width: 25px;padding: 8px 12px 3px 2px;float: left;">
                                                        <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/menu15.png" style="height: 25px;width: 25px;">
                                                    </div>
                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                        机构通讯录
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  个人、机构、子账号通用    -->
                                        <!--                                        <div v-if="user.userType != 3">-->
                                        <!--                                            <div style="width: 8px;height: 22px;background: #031F88;float: left;margin-top: 10px;"></div>-->
                                        <!--                                            <div style="width: 500px;height: 30px;line-height: 25px;font-weight: bold;font-size: 18px;color: #031F88;margin-left: 20px;margin-top: 10px;">服务商中心</div>-->
                                        <!--                                            <div style="height: auto;width: 90%;margin: 0 auto;">-->
                                        <!--                                                <div class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="shopBackstage(0)">-->
                                        <!--                                                    <div style="height: 25px;width: 25px;padding: 8px 12px 1px 2px;float: left;">-->
                                        <!--                                                        <img src="~@/assets/imgs/index/head10.png" style="height: 25px;width: 25px;">-->
                                        <!--                                                    </div>-->
                                        <!--                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">-->
                                        <!--                                                        我的店铺-->
                                        <!--                                                    </div>-->
                                        <!--                                                </div>-->
                                        <!--                                                <div  class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="shopBackstage(1)">-->
                                        <!--                                                    <div style="height: 25px;width: 25px;padding: 8px 12px 1px 2px;float: left;">-->
                                        <!--                                                        <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/menu11.png" style="height: 25px;width: 25px;">-->
                                        <!--                                                    </div>-->
                                        <!--                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">-->
                                        <!--                                                        店铺后台管理-->
                                        <!--                                                    </div>-->
                                        <!--                                                </div>-->
                                        <!--                                                <div  class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="shopBackstage(3)">-->
                                        <!--                                                    <div style="height: 25px;width: 25px;padding: 8px 12px 1px 2px;float: left;">-->
                                        <!--                                                        <img src="~@/assets/imgs/index/head11.png" style="height: 25px;width: 25px;">-->
                                        <!--                                                    </div>-->
                                        <!--                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">-->
                                        <!--                                                        商家订单管理-->
                                        <!--                                                    </div>-->
                                        <!--                                                </div>-->
                                        <!--                                                <div class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="a('')">-->
                                        <!--                                                    <div style="height: 25px;width: 25px;padding: 8px 12px 1px 2px;float: left;">-->
                                        <!--                                                        <img src="~@/assets/imgs/index/head12.png" style="height: 25px;width: 25px;">-->
                                        <!--                                                    </div>-->
                                        <!--                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">-->
                                        <!--                                                        商家合同管理-->
                                        <!--                                                    </div>-->
                                        <!--                                                </div>-->
                                        <!--                                                <div class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="shopBackstage(6)">-->
                                        <!--                                                    <div style="height: 25px;width: 25px;padding: 8px 13px 1px 2px;float: left;">-->
                                        <!--                                                        <img src="~@/assets/imgs/index/head13.png" style="height: 25px;width: 25px;">-->
                                        <!--                                                    </div>-->
                                        <!--                                                    <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">-->
                                        <!--                                                        商家发票管理-->
                                        <!--                                                    </div>-->
                                        <!--                                                </div>-->
                                        <!--                                            </div>-->
                                        <!--                                        </div>-->
                                    </div>
                                    <!--    菜单右侧 -->
                                    <div style="width: 230px; background-color: #FBFBFB;margin-left: 7px;">
                                        <div style="width: 8px;height: 22px;background: #031F88;float: left"></div>
                                        <div style="width: 500px;height: 30px;line-height: 25px;font-weight: bold;font-size: 18px;color: #031F88;margin-left: 20px;">快捷发布</div>
                                        <div style="height: auto;width: 90%;margin: 0 auto;box-sizing: content-box;">
                                            <!--                                        <div class="hand op sc menu" style="height: 40px;overflow:hidden;width: 100%;">-->
                                            <!--                                            <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">-->
                                            <!--                                                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_dynamic.png" style="height: 25px;width: 25px;">-->
                                            <!--                                            </div>-->
                                            <!--                                            <div style="height: auto;overflow:hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">-->
                                            <!--                                                动态帖子-->
                                            <!--                                            </div>-->
                                            <!--                                        </div>-->
                                            <!--   草稿箱-->
                                            <div  class="hand op sc menu" style="height: 40px;overflow:hidden;width: 100%;" @click="a('/content/add/' + 0)">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_essay.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div style="height: auto;overflow:hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                    发布文章
                                                </div>
                                            </div>
                                            <!--   发布红包问答 -->
                                            <div  class="hand op sc menu" style="height: 40px;overflow:hidden;width: 100%;" >
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/material2.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <el-dropdown>
                                                <span @click="openQuesEdidor(1)" style="height: auto;float: left;line-height: 40px;font-size: 15px;cursor: pointer;font-weight: bold;color:#000000">
                                                    发布红包问答<i class="el-icon-caret-bottom"></i>
                                                </span>
                                                    <el-dropdown-menu slot="dropdown">
                                                        <div @click="openQuesEdidor(1)"><el-dropdown-item icon="el-icon-platform-eleme">红包问答</el-dropdown-item></div>
                                                        <div @click="openQuesEdidor(2)"><el-dropdown-item icon="el-icon-coin">悬赏资料</el-dropdown-item></div>
                                                        <div @click="openQuesEdidor(3)"><el-dropdown-item icon="el-icon-chat-line-round">悬赏讨论</el-dropdown-item></div>
                                                        <div @click="openQuesEdidor(4)"><el-dropdown-item icon="el-icon-s-finance">必选竞标</el-dropdown-item></div>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </div>
                                            <!--    发布模板信息 -->
                                            <div class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" >
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/material.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <el-dropdown :hide-on-click="false">
                                                                <span @click="project" style="height: auto;float: left;line-height: 40px;font-size: 15px;cursor: pointer;font-weight: bold;color:#000000">
                                                                    发布模板信息<i class="el-icon-caret-bottom"></i>
                                                                </span>
                                                    <el-dropdown-menu slot="dropdown">
                                                        <div @click="project(1)"><el-dropdown-item icon="el-icon-folder-opened">招标信息</el-dropdown-item></div>
                                                        <div @click="project(2)"><el-dropdown-item icon="el-icon-suitcase-1">采购信息</el-dropdown-item></div>
                                                        <div @click="project(3)"><el-dropdown-item icon="el-icon-notebook-2">招募信息</el-dropdown-item></div>
                                                        <div @click="project(4)"><el-dropdown-item icon="el-icon-collection">商务信息</el-dropdown-item></div>
                                                        <div @click="project(5)"><el-dropdown-item icon="el-icon-shopping-bag-1">招商信息</el-dropdown-item></div>
                                                        <div @click="project(6)"><el-dropdown-item icon="el-icon-box">需求信息</el-dropdown-item></div>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </div>
                                            <!--   上传知识仓库 -->
                                            <div class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="a('/user/collection')">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_demand.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <el-dropdown>
                                                        <span  @click="handleClick(0)" style="height: auto;float: left;line-height: 40px;font-size: 15px;cursor: pointer;font-weight: bold;color:#000000">
                                                                        上传资料<i class="el-icon-caret-bottom"></i>
                                                                    </span>
                                                    <el-dropdown-menu slot="dropdown">
                                                        <div @click="handleClick(0)"><el-dropdown-item icon="el-icon-tickets">上传文章资料</el-dropdown-item></div>
                                                        <div @click="materials()"><el-dropdown-item icon="el-icon-picture">上传图片资料</el-dropdown-item></div>
                                                        <div @click="electronics()"><el-dropdown-item icon="el-icon-video-camera-solid">上传视频资料</el-dropdown-item></div>
                                                        <div @click="attachment()"><el-dropdown-item icon="el-icon-s-cooperation">上传素材资料</el-dropdown-item></div>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </div>
                                        </div>

                                        <div style="width: 8px;height: 22px;background: #031F88;float: left;margin-top: 10px"></div>
                                        <div style="width: 500px;height: 30px;line-height: 25px;font-weight: bold;font-size: 18px;color: #031F88;margin-left: 20px;margin-top: 10px;">管理</div>
                                        <div style="height: auto;width: 90%;margin: 0 auto;box-sizing: content-box;">
                                            <div class="hand op sc menu" style="height: 40px;overflow:hidden;width: 100%;" @click="a('/content/add/' + 0)">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_draft.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div style="height: auto;overflow:hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                    我的草稿箱
                                                </div>
                                            </div>
                                            <div class="hand op sc menu" style="height: 40px;overflow:hidden;width: 100%;" @click="a('/room/index?type=myRoom')">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_room.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div style="height: auto;overflow:hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                    我的ROOM
                                                </div>
                                            </div>
                                            <!--    我的红包问答       /circle/mySpace/'+user.uuid+'?type=questions -->
                                            <div class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="a('/more/ask')">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_questions.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div style="height: auto;overflow: hidden;width: calc(100% - 46px);float: left;line-height: 40px;font-size: 15px;cursor: pointer;font-weight: bold;">
                                                    我的红包问答
                                                </div>
                                            </div>
                                            <!--   我的需求信息     /circle/mySpace/'+user.uuid+'?type=message -->
                                            <div class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="a('/more/need')">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_document.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div style="height: auto;overflow: hidden;width: calc(100% - 46px);float: left;line-height: 40px;font-size: 15px;cursor: pointer;font-weight: bold;">
                                                    我的需求信息
                                                </div>
                                            </div>
                                            <div v-if="user.userType != 3"  class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="a('/index/filingCabinet/')">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_filing%20cabinet.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div style="height: auto;overflow: hidden;width: calc(100% - 46px);float: left;line-height: 40px;font-size: 15px;cursor: pointer;font-weight: bold;">
                                                    我的文件柜
                                                </div>
                                            </div>
                                            <!--    我的收藏夹  -->
                                            <div v-if="user.userType != 3"  class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="circleCollect(favorite)">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_collect.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div style="height: auto;overflow: hidden;width: calc(100% - 46px);float: left;line-height: 40px;font-size: 15px;cursor: pointer;font-weight: bold;">
                                                    我的收藏夹
                                                </div>
                                            </div>
                                            <div v-if="user.userType != 3"  class="hand op sc menu" style="height: 40px;overflow:hidden;width: 100%;" @click="a('/circle/index?show_name=activity')">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_activity.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div style="height: auto;overflow:hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                    我的活动
                                                </div>
                                            </div>
                                            <!--   环境页 -->
                                            <div v-if="user.userType == 1" class="hand op sc menu" style="height:40px;overflow: hidden;width: 100%;" >
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 5px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_environment.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div v-if="user.userType == 1" style="height:auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                    我的环境页
                                                </div>
                                                <!--                                                <div v-else style="height:auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">-->
                                                <!--                                                    机构环境页-->
                                                <!--                                                </div>-->
                                            </div>
                                            <div v-if="user.userType != 3" class="hand op sc menu" style="height: 40px;overflow: hidden;width: 100%;" @click="templist">
                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_customize.png" style="height: 25px;width: 25px;">
                                                </div>
                                                <div style="height: auto;overflow: hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">
                                                    自定义模板文档
                                                </div>
                                            </div>
                                        </div>

                                        <!--                                    <div v-if="user.userType != 3">-->
                                        <!--                                        <div style="width: 8px;height: 22px;background: #031F88;float: left;"></div>-->
                                        <!--                                        <div style="width: 500px;height: 30px;line-height: 25px;font-weight: bold;font-size: 18px;color: #031F88;margin-left: 20px;margin-top: 10px;">账户设置</div>-->
                                        <!--                                        <div v-if="user.userType == 1" style="height: auto;width: 90%;margin: 0 auto;box-sizing: content-box;">-->
                                        <!--                                            <div class="hand op sc menu" style="height: 40px;overflow:hidden;width: 100%;">-->
                                        <!--                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">-->
                                        <!--                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_organization.png" style="height: 25px;width: 25px;">-->
                                        <!--                                                </div>-->
                                        <!--                                                <div style="height: auto;overflow:hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">-->
                                        <!--                                                    账号信息及认证-->
                                        <!--                                                </div>-->
                                        <!--                                            </div>-->
                                        <!--                                        </div>-->
                                        <!--                                        <div v-if="user.userType == 2" style="height: auto;width: 90%;margin: 0 auto;box-sizing: content-box;">-->
                                        <!--                                            <div class="hand op sc menu" style="height: 40px;overflow:hidden;width: 100%;">-->
                                        <!--                                                <div style="height: 25px;width: 25px;padding: 8px 12px 9px 2px;float: left;">-->
                                        <!--                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/img_organization.png" style="height: 25px;width: 25px;">-->
                                        <!--                                                </div>-->
                                        <!--                                                <div style="height: auto;overflow:hidden;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 15px;font-weight: bold;cursor: pointer;">-->
                                        <!--                                                    机构信息及认证-->
                                        <!--                                                </div>-->
                                        <!--                                            </div>-->
                                        <!--                                        </div>-->
                                        <!--                                    </div>-->
                                    </div>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div style="width: 2px;margin:15px 0;height: 20px;background: #fff;float: right"></div>
                </div>
            </div>
            <el-dialog :close-on-click-modal="false" :visible.sync="dialogShow" :append-to-body="true" width="960px" clcaa="begin">
                <Askquestion v-if="dialogShow" @close="hideChild"  :awType="shop" :form="1"></Askquestion>
            </el-dialog>
            <el-dialog :modal="false" top="110px" :close-on-click-modal="false" :visible.sync="showCalendar" width="920px" :show-close="false" class="event-form">
                <i class="el-icon-close hand op" style="z-index: 1000; font-size: 24px;position: absolute;right: 20px;top: 8px;cursor: pointer;" @click="showCalendar = false"></i>
                <div style="overflow: hidden">
					<Calendats></Calendats>
                </div>
            </el-dialog>
			<add-image-file-base-form :is-show="showAddImageFileBaseForm" :on-close="function (){showAddImageFileBaseForm = false;}"></add-image-file-base-form>
            <el-dialog :close-on-click-modal="false" width="482px" :visible.sync="uploadvideo" :show-close=false :append-to-body="true" class="upload_att">
				<addUploadVideo ref="video_code"></addUploadVideo>
            </el-dialog>
            <el-dialog :close-on-click-modal="false" :visible.sync="uploadAttachment" width="705px" :append-to-body="true" style="padding: 30px">
				<addUploadAttachment ></addUploadAttachment>
            </el-dialog>
        </div>
        <el-dialog :close-on-click-modal="false" :visible.sync="authShow" :append-to-body="true" >
			<authentication  :userType="user.userType"></authentication>
        </el-dialog>

        <!--切换账号组件-->
        <el-dialog :visible.sync="showLogin" :close-on-click-modal="false" :append-to-body="isAppendToBody" width="800px" class="begin">
            <AccountSelection :userOptions="userOptions"  :onClose="function (){showLogin = false}" ></AccountSelection>
        </el-dialog>
        <el-dialog :visible.sync="proJect" :close-on-click-modal="false" :append-to-body="isAppendToBody" width="900px" class="begin">
            <proJect></proJect>
        </el-dialog>
        <el-dialog :visible.sync="purChase" :close-on-click-modal="false" :append-to-body="isAppendToBody" width="900px" class="begin">
            <purChase></purChase>
        </el-dialog>
        <el-dialog :visible.sync="recRuit" :close-on-click-modal="false" :append-to-body="isAppendToBody" width="900px" class="begin">
            <recRuit></recRuit>
        </el-dialog>
        <el-dialog :visible.sync="buSiness" :close-on-click-modal="false" :append-to-body="isAppendToBody" width="900px" class="begin">
            <buSiness></buSiness>
        </el-dialog>
        <el-dialog :visible.sync="invesTment" :close-on-click-modal="false" :append-to-body="isAppendToBody" width="900px" class="begin">
            <invesTment></invesTment>
        </el-dialog>
        <el-dialog :visible.sync="deMand" :close-on-click-modal="false" :append-to-body="isAppendToBody" width="900px" class="begin">
            <deMand></deMand>
        </el-dialog>
        <!--扩容-->
        <div class="dialog-container">
            <el-dialog :visible.sync="isSpaceSizeWin" width="1200px" custom-class="login-dialog" class="custom-dialog" top="10vh">
                <spaceSizeWin :type="3"/>
            </el-dialog>
        </div>
        <div class="dialog-container">
            <el-dialog :visible.sync="isSubAccountWin" width="1200px" custom-class="login-dialog" class="custom-dialog" top="10vh">
                <subAccountWin :type="3"/>
            </el-dialog>
        </div>
        <!--支付成功-->
        <div class="dialog-container">
            <el-dialog :visible.sync="isPaySuccessWin" width="1200px" custom-class="login-dialog" class="custom-dialog" top="10vh">
                <pay-success-win />
            </el-dialog>
        </div>
	</div>
</template>
<script>
	import Askquestion from "./Askquestion";
	import addImageFileBaseForm from "./addImageFileBaseForm.vue";
	import addUploadVideo from "./addUploadVideo.vue";
	import addUploadAttachment from "./addUploadAttachment.vue";
	import Calendats from "@/components/common/Calendats.vue";
	import authentication from "./authentication/authentication";
    import AccountSelection from "./AccountSelection";
    import proJect from "@/components/common/tempLate/proJect";
    import purChase from "@/components/common/tempLate/purChase";
    import recRuit from "@/components/common/tempLate/recRuit";
    import buSiness from "@/components/common/tempLate/buSiness";
    import invesTment from "@/components/common/tempLate/invesTment";
    import deMand from "@/components/common/tempLate/deMand";
	import store from "@/store";
	import substr from "voca/substr";
    import spaceSizeWin from "./Pay/vip/expand/spaceSizeWin";
    import subAccountWin from "./Pay/vip/expand/subAccountWin";
    import paySuccessWin from "./Pay/vip/paySuccessWin";

	export default {
		name: "Head",
		components: {
			Calendats,
			Askquestion,
			addImageFileBaseForm,
			addUploadVideo,
			addUploadAttachment,
			authentication,
            AccountSelection,
            proJect,
            purChase,
            recRuit,
            buSiness,
            invesTment,
            deMand,
            paySuccessWin,
            spaceSizeWin,
            subAccountWin
		},
		data() {
			return {
				like:'',
				type_name: 'content',
				wx_code:"",
				wx_openid:"",
				menu:0,
				etmyshop:{},
				MyShop:[],
				showAddImageFileBaseForm:false,
				showCalendar:false,
				dialogShow :false,
				uploadvideo:false,
				uploadAttachment:false,
				authShow:false,
                showLogin:false,
                proJect:false,
                purChase:false,
                recRuit:false,
                buSiness:false,
                invesTment:false,
                deMand:false,
                isAppendToBody:true,
                userOptions:[],
                isSpaceSizeWin:false,
                isPaySuccessWin:false,
                isSubAccountWin:false,
			};
		},

		computed: {
			routes(){
				if (this.$route.path=="/"){
					return  "/";
				}else {
					const segments = this.$route.path.split('/'); // 将路径按照斜杠分割
					return segments[1];
				}

        },
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        },
    },

    created:function(){
        var that = this;
        this.$EventBus.$EventBus.$off("payMsg")
        this.$EventBus.$on("payMsg", (data) => {
            this.$nextTick(() => {
                if(isSpaceSizeWin == true || isSubAccountWin == true){
                    that.isSpaceSizeWin = false;
                    that.isSubAccountWin = false,
                        that.isPaySuccessWin = true;
                }
            })
        })
    },

    mounted() {
        if(this.is_login == 1){
            this.getTodayCalendarEventList();
        }
    },
    methods: {
        reg(){
            var that = this;
            that.utils.a('/index/reg');
        },

        getTodayCalendarEventList(){
            var that = this;
            var date = new Date().getFullYear()+"_"+(new Date().getMonth() + 1)+"_"+new Date().getDate();
            this.newApi.getTodayCalendarEventList({roomId:0}).then((ret)=>{
                if (ret.isSuccess == 1 && ret.data.length > 0 && !localStorage.getItem(date)){
                    localStorage.setItem(date,"1");
                    that.$alert("今日有"+ret.data.length+"个日历事件", '温馨提示', {
                        confirmButtonText: '点击查看',
                        callback:function (){
                            that.showCalendar = true;
                        }
                    });
                }
            }).catch((err)=>{
                console.log(err)
            })
        },

        mouseover(){
            this.$refs.eldropdewn.show();
        },

        //弹窗提示
        open(openid) {
            var that = this;
            this.$confirm('未绑定微信请先登录在绑定 或 去注册?', '提示', {
                distinguishCancelAndClose:true,
                confirmButtonText: '绑定',
                cancelButtonText: '注册',
                type: 'warning'
            }).then(() => {
                //登录 发布事件
                this.$EventBus.$emit("indexClickLogin", {indexClickLogin: 1,wx_openid:openid});
                // that.$router.push({
                //   name: 'mySpace',
                //   params: {openid: openid}
                // })
            }).catch(action => {
                if(action === 'cancel'){
                    that.$router.push({
                        name: 'reg',
                        params: {wx_openid: openid}
                    })
                }
            });
        },

        //切换账号
        switchAccounts(){
            var that = this;
            that.newApi.getLoginCodeList({}).then(res=>{
                if(res.isSuccess == 1){
                    that.userOptions = res.data;
                    that.showLogin = true;
                }
            })
        },

        set_route(route){
                this.$router.push({ path: route });
                this.routes = route;
        },

        login_out() {
            localStorage.token = '';
            localStorage.uuid = '';
            // 清除支付参数（如果需要的话）
            localStorage.removeItem('payParams');
				this.$router.push({ path: '/' });
            this.$store.commit("login_out");
            this.$store.state.subList = [];
            this.showLogin = false;

        },

        search(){
            var rou = window.location.pathname;
            var name = this.type_name;
            if(this.like != ""){
                if(rou.indexOf("/room/info") == -1){
                    setTimeout(()=>{
                        window.open('/index/search?name='+name+'&like='+this.like ,'_blank');
                    },500)
                }else{
                    this.$EventBus.$emit("search", 'search');
                    this.$store.commit('set_like', this.like);
                }
            }
        },

        //点击登录 发布事件
        NeedLogin(){
            store.commit('setShowLoginPopup', true);
        },

        templist(){
            var that = this;
            that.$router.push("/room/template")
        },

        toAuth(){
            var that = this;
            that.$router.push('/archives')
        },

        circle(){
            var that = this;
            if(that.user.userType === 1){
                that.utils.a('/circle/mySpace/' + that.user.uuid)
            }else if (that.user.userType === 2 || that.user.userType === 3){
                that.utils.a('/circle/organSpace/' + that.user.uuid)
            }
        },

        enterprise(){
            var that = this;
            if(that.user.userType == 2){
                that.utils.err("企业店铺还在开发中！")
            }
        },
        project(type){
            if (this.user.realNameStatus !== 2){
                store.commit('setShowAuthPopup', 'realName');
            }else if (type === 1){
                this.proJect = true;
            }else if(type === 2){
                this.purChase = true;
            }else if(type === 3){
                if (this.user.userType === 2 ){
                    this.recRuit = true
                }else {
                    this.utils.err('只有机构账号才能发布招募信息');
                    return false
                }
            }else if(type === 4){
                if (this.user.userType === 2){
                    this.buSiness = true
                }else {
                    this.utils.err('只有机构账号才能发布商务信息');
                    return false
                }
            }else if(type === 5){
                if (this.user.userType === 2 ){
                    this.invesTment = true
                }else {
                    this.utils.err('只有机构账号才能发布招商信息');
                    return false
                }
            }else if(type === 6){
                this.deMand = true
            }

        },

        handleClick:function (id){
            var that = this;
            if (!this.utils.toAuth(1)) {
                return false
            }
            that.utils.a('/filebase/upload/' + id)
        },

        materials(){
            var that = this;
            if (!this.utils.toAuth(1)) {
                return false
            }
            // that.utils.a("/filebase/materials/7/0")
            that.showAddImageFileBaseForm = true

        },

        electronics(){
            var that = this;
            if (that.user.realNameStatus == 0 || that.user.realNameStatus == 1) {
                that.utils.err("只有实名认证后才能上传视频");
                that.$router.push({path: '/circle/mySpace/'+that.user.uuid, query: {realNameAuth: that.user.realNameStatus}});
            }else {
                // that.utils.a("/filebase/materials/6/0")
                that.uploadvideo = true
            }
        },

        attachment(){
            var that = this;
            if (!this.utils.toAuth(1)) {
                return false
            }
            // that.utils.a("/filebase/materials/8/0")
            that.uploadAttachment = true
        },

        // 获取我的店铺
        shopBackstage(type){
            var that = this;
            if (that.user.userType == 3){
                that.utils.err("子账没有店铺和后台，并且不能创建");
                return false
            }
            that.authShow = false;
            if(that.user.realNameStatus == 0){
                this.utils.confirm("未实名认证,前往认证", () => {
                    //实名组件
                    that.authShow = true;
                })
                return false;
            }else if(that.user.realNameStatus == 1){
                that.utils.msg("实名认证审核中");
                return false;
            }else if(that.user.realNameStatus == 2){
                that.newApi.getMyShop({}).then((ret)=>{
                    if(ret.isSuccess === 1){
                        that.etmyshop = ret.data;
                        if(that.etmyshop !== null){
                            if(that.etmyshop.status == 1){
                                that.utils.msg("店铺审核中");
                                return false
                            }
                            if(that.etmyshop.status == 2){
                                that.utils.msg("店铺审核未通过");
                                return false
                            }
                            if(that.etmyshop.status == 4){
                                that.utils.msg("店铺已封禁");
                                return false
                            }
                            if(type == 0){
                                //店铺首页
                                that.utils.a("/shop/shopCode");
                            }else{
                                //店铺后台
                                that.utils.a('/shop/shopBackstage?type='+type);
                            }
                        } else {
                            //店铺首页
                            that.utils.b("/shop/serVice");
                            return false
                        }}
                })
            }
        },

        // 通知消息
        message(){
            window.open('/message/index')
        },
        add_money(type){
            this.utils.a('/user/index/'+type);
            store.commit('setShowLoginPopup', false);
        },
        circleType(){
            this.utils.a('/circle/mySpace/'+this.user.uuid+'?showRight=space');
        },
        circleCollect(){
            this.utils.a('/circle/index?show_name=favorite');
        },
        // 机构通讯录
        addressbook(){
            this.utils.a('/circle/index?show_name=organ');
        },
        // 机构子账号管理
        addSubaccount(){
            this.utils.a('/circle/organSpace/' + this.user.uuid + '?checkoutBody=' + (false ? 'true' : 'false'));
        },
        getMywallet(){
            var that = this;
            that.authShow = false;
            if(that.user.realNameStatus == 0){
                this.utils.confirm("未实名认证,前往认证", () => {
                    //实名组件
                    that.authShow = true;
                })
                return false;
            }else if(that.user.realNameStatus == 1){
                that.utils.msg("实名认证审核中");
                return false;
            }else if(that.user.realNameStatus == 2){
                this.utils.a('/user/index/1');
            }
        },
        expert(){
            if (this.user.authenticationStatus !== 2 || this.user.expertAuthStatus !== 2){
                this.utils.err("请先通过专家认证！");
                return false
            }else {
                this.utils.a('/expert/space/' +0)
            }
        },
        addbecome(){
            var that = this;
            if (!this.utils.toAuth(2)) {
                return false
              }
                this.utils.a('/expert/become/')
            },
			openQuesEdidor(type){
				var name = ["","红包问答","悬赏资料","悬赏讨论","比标竞选"];
				if (this.is_login === 0){
					this.utils.err("请先登录！");
					return false
				}
				if(this.user.realNameStatus == 0 || this.user.realNameStatus == 1){
					this.utils.err("只有实名认证后才能发布"+name[type]);
					this.$router.push({path: '/circle/mySpace/'+this.user.uuid, query: {realNameAuth: this.user.realNameStatus}});
				}else {
					this.shop = type.toString();
					this.dialogShow = true;
				}
			},
			hideChild(){
				this.dialogShow = true;
			},
			// 接收子组件通信
			contentvideo (){
				this.uploadvideo = false
				this.get_videos_list()
				this.$refs.video_code.videos = "";
				this.$refs.video_code.inputvideo = "";
				this.$refs.video_code.types = "";
			},
            // 点击logo回退到首页
            add_return(){
                this.$router.push({ path: '/' });
        },
        complete(){
            this.uploadAttachment = false;
        }
    },
};
</script>

<style scoped>
.menu:hover{
    background:#EFEFEF;height:100%;
}
*{
    font-size: 14px;
}
a{
    text-decoration:none; color:rgb(166, 166, 166);
}
.head_white{
    height: 26px;
    padding: 5px 0;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.head_white_word{
    width: 90px;
    height: 26px;
    text-align: center;
    line-height:50px;
    font-size: 16px;
    float: left;
    /*font-family: "Microsoft YaHei", Arial, sans-serif;*/
    font-family: "Microsoft YaHei", Arial, sans-serif;
    font-weight: bold;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tab{
    text-align: center;
		margin: auto 40px;
    text-align: center;
    cursor:pointer;
}
.up{
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.1s;
}
.head{
    width:100%;
    z-index:10;
    height: 35px;
    margin: 5px auto;
}

/deep/.text .el-input--suffix {
    font-size: 12px;
}

/deep/.text .el-input__inner{
    border-radius: 23px !important;
    padding: 0 25px;
    height: 33px;
    line-height: 25px;
    border: 1px solid rgba(0, 0, 0, 0.35);
}
/deep/.text .el-input__icon{
    height: 35px;
    width: 20px;
    line-height: 35px;
}
.el-popper[x-placement^=bottom]{
    margin-top: 10px;
}
.el-popper[x-placement^=bottom].popper__arrow{
    display: none;
}
/deep/.el-divider--vertical{
    margin: 0 3px;
}
/deep/.text .el-input__icon{
    height: 30px;
    line-height: 30px;
}
.triangle {
    width: 0;
    height: 0;
    border-bottom: 41px solid #FFFFFF;
    border-left: 59px solid transparent;
}
.head_white_word.active {
    width: 90px;
    height: 25px;
    border-radius: 20px;
    background-color: #FFFFFF;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-family: "Microsoft YaHei", Arial, sans-serif;
    font-weight: bold;
    color: #031F88;
    display: flex;
    justify-content: center;
    align-items: center;

}
/deep/ [class*=" el-icon-"], [class^=el-icon-] {
    font-size: 16px;
    margin: 2px;

}
/deep/ .event-form .el-dialog__header {
    padding: 0;
}

/deep/ .event-form .el-dialog__body {
    padding: 0;
}

/deep/ .event-form .el-dialog {
    margin-bottom: 10px;
}

/deep/.el-dialog__header {
    padding: 30px 20px 10px;
}
/deep/ .el-dialog__body{
    padding: 0px 0px;
}
/deep/ .el-dialog__header{
    padding: 0px;
}
/deep/ .el-dialog__body {
    padding: 0px;
}
/deep/ .dialog-container .el-dialog {
    border-radius: 10px !important;
}
</style>

<style>
.upload_att .el-dialog__body {
    padding: 28px 2px !important;
}
.upload_att .el-dialog__header {
    padding: 0px !important;
}
.begin .el-dialog__header {
    padding: 30px 20px 10px;
}
.begin .el-dialog__body {
    padding: 0px 0px;
}
</style>
