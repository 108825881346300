import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/base.css';
import fileDownload from 'js-file-download'
import router from '../router/index';
import Oss from '@/utils/Oss';
import axios from 'axios';
import {sha256} from "js-sha256";
import newApi from "@/utils/newApi";
import store from "@/store";
import {win} from "../../public/tinymce";


const utils = {
    //当前页面跳转
    a(url,refresh){
        // window.location.href = url
        router.push({ path: url });
        if(refresh == 1){
            router.go(0);
        }
    },
    //新窗口跳转
    b(url){
        window.open(url,'_blank');
    },
    //消息成功提示
    sus(content){
        Vue.prototype.$message({
            duration:1000,
            message: content,
            type: 'success'
        });
    },
    //消息失败提示
    err(content){
        Vue.prototype.$message.error(content);
    },
    //消息提示
    msg(content){
        Vue.prototype.$message({
            message: content,
            type: 'warning'
        });
    },
    loading(content){
        var loading = Vue.prototype.$loading({
            text: content,
            spinner: 'el-icon-loading',
        });
        return loading;
    },
    //浏览器弹窗
    alert(content,CallBack=""){
        Vue.prototype.$alert(content, '温馨提示', {
            confirmButtonText: '确定',
            callback(){
                if (typeof(CallBack) == "function") {
                    CallBack();
                }
            }
        });
    },
    //windows侧边栏消息弹窗提示
    notice(title,content){
        if(window.Notification && Notification.permission !== "denied") {
            Notification.requestPermission(function(status) { // eslint-disable-line no-unused-vars
                new Notification(title, { body: content });// eslint-disable-line  no-new
            });
        }
    },
    //消息提示
    toast(content,url = "",isShow = 1){
        var notify;
        if(isShow == 1){
            notify = Vue.prototype.$notify({
            title: '消息通知',
            message: content,
            duration: 0, // 设置为0表示通知不会自动关闭
            onClick: () => {
                // 执行其他操作
                if(url != "" && isNaN(url)){
                    window.open(url,'_blank');
                    // 关闭通知
                    notify.close();
                }
            },
            customClass: 'custom-cursor-style' // 自定义样
        });
        }
    },
    //删除提示
    confirm(content,CallBack){
        Vue.prototype.$confirm(content,'温馨提示',{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(value => {
            if (typeof(CallBack) == "function") {
                CallBack(value)
            }
        }).catch(_ => {
            console.log(_);
        });
    },
    //删除提示
    confirmToesc(content,CallBack,btn1 ="确定",bt2="取消",CallBack2){
        Vue.prototype.$confirm(content,'温馨提示',{
            confirmButtonText: btn1,
            cancelButtonText: bt2,
            type: 'warning'
        }).then(value => {
            if (typeof(CallBack) == "function") {
                CallBack(value)
            }
        }).catch(_ => {
            if (typeof(CallBack2) == "function") {
                CallBack2()
            }
        });
    },
    //el选项弹窗
    prompt(content,CallBack,buttonA="确定"){
        Vue.prototype.$prompt(content, '温馨提示', {
            confirmButtonText:buttonA,
            cancelButtonText: '取消'
        }).then(({ value }) => {
            if (typeof(CallBack) == "function") {
                CallBack(value)
            }
        }).catch(() => {
        });
    },
    //axios get请求文件下载
    download(url = "",name = ""){
        newApi.addFileCabinet({name:name,url:url}).then(res => {
                if (res.isSuccess == 1){
                    axios.get(url, {responseType: 'arraybuffer'}).then(res => {
                        fileDownload(res.data, name)
                    })
                }
            })
    },
    //获取随机数
    random_string(len){
        len = len || 32;
        var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        var maxPos = chars.length;
        var pwd = '';
        for (var i = 0; i < len; i++) {
            pwd += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },
    //随机数(纯数字)
    random_number(){
        var mm=Math.random();
        var six = "";
        if(mm>0.1){
            six=Math.round(mm*1000000);
        }else{
            mm += 0.1;
            six = Math.round(mm*1000000);
        }
        return six;
    },
    /**
     * 上传文件
     * @param file 文件
     * @param CallBack 上传回调
     * @param progressCallBack 上传进度回调
     * @returns {Promise<void>}
     */
    async upload(file,CallBack,progressCallBack=null) {
        let resData = {};
        async function readFileAsArrayBuffer(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => resolve(event.target.result);
                reader.onerror = (error) => reject(error);
                reader.readAsArrayBuffer(file);
            });
        }
        const fileArrayBuffer = await readFileAsArrayBuffer(file);
        const fileUint8Array = new Uint8Array(fileArrayBuffer);
        const fileHash = sha256(fileUint8Array);
        if (localStorage.getItem("token") !== "" && localStorage.getItem("token") != null && typeof localStorage.getItem("token") != 'undefined') {
            resData = await newApi.getFileInfo({hash: fileHash,size:file.size}).then(async res => {
                if (res.isSuccess == 1){
                    if(res.data.code == -1){
                        this.confirm(res.data.msg,function () {
                            window.open('/member','_blank');
                        })
                        return false;
                    }
                    if (res.data.code === 1) {
                        res.data.data.name = file.name;
                        return res.data.data;
                    } else {
                        let data = {}
                        if(file.name == "undefined"){
                            const fileName  = this.random_string(10) + ".jpg";
                            let type = file.type;
                            const fileWithCorrectName = new File([file], fileName, { type: type });
                            data = await Oss.uploadToOSS(fileWithCorrectName, fileHash,progressCallBack);
                        }else{
                            data = await Oss.uploadToOSS(file, fileHash,progressCallBack);
                        }
                        return data;
                    }
                }else {
                    return false;
                }

            })
            if (typeof(CallBack) == "function") {
                CallBack(resData)
            }
        } else {
            this.err("请先登录");
        }
    },
    //获取URL地址参数
    getUrlParams(field){
        if (window.location.href.indexOf(field) >= 0) {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == field){
                    return pair[1];
                }
            }
        }
    },
    //获取URL地址参数
    getUrlType() {
        let url = store.state.devApiUrl;
        let subdomain = "";
        if(url.includes("localhost")) {
            subdomain = "localhost";
        } else if(url.includes("devservice")) {
            subdomain = "dev";
        } else if(url.includes("service")) {
            subdomain = "prod";
        } else {
            // 通过正则表达式匹配获取域名部分
            const domain = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/img);
            subdomain = domain[0].split('.')[0];
        }
        return subdomain;
    },
    //实名认证跳转
    realNameAuthJump(uuid,type = 0, userType){
        var msg = "";
        if(type == 0){
            msg = "未实名认证,前往认证";
        }else if(type == 1){
            msg = "实名审核中,是否前往查看?";
        }
        Vue.prototype.$confirm(msg,'温馨提示',{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            router.push({path: '/auth'});
        }).catch(_ => {
            console.log(_);
        });
    },
    //四舍五入保留2位小数（不够位数，则用0替补）
    keepTwoDecimalFull(num) {
        var result = parseFloat(num);
        if (isNaN(result)) {
            this.err('传递参数错误，请检查！');
            return;
        }
        result = Math.round(num * 100) / 100;
        var s_x = result.toString(); //将数字转换为字符串

        var pos_decimal = s_x.indexOf('.'); //小数点的索引值


        // 当整数时，pos_decimal=-1 自动补0
        if (pos_decimal < 0) {
            pos_decimal = s_x.length;
            s_x += '.';
        }

        // 当数字的长度< 小数点索引+2时，补0
        while (s_x.length <= pos_decimal + 2) {
            s_x += '0';
        }
        return s_x;
    },
    toText(HTML,num){
        const processedText = HTML
            .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '') // Remove style, script, iframe tags and content
            .replace(/<[^>]+?>/g, '') // Remove other HTML tags
            .replace(/&nbsp;/gi, ' ') // Replace &nbsp; with a space
            .replace(/\s+/g, ' ')// Replace multiple spaces with a single space
            .replace(/&ldquo;/gi, '"')
            .replace(/&rdquo;/gi, '"')
            .replace(/\.(jpg|jpeg|png|gif)$/i,'') // 过滤文章显示图片的正则
            .replace(/https?:\/\/[^\s]+\.(jpg|jpeg|png|gif)/gi,'') //过滤文章显示视频的正则
            .trim();
        // Remove leading and trailing spaces
        if (num == null){
            num = 200;
        }
        return processedText.substring(0, num);
    },
    /**
     * 级联回显
     * @param {idlists} key
     * @param {数据树} treeData
     * @param {id属性名} fielKey
     */
    cascader(key, treeData, fielKey) {
        let echoTreeArr = [];
        let eachAry;
        //回显分类value转为数组
        eachAry =JSON.parse(JSON.stringify(key))
        let itemAry = [];//分类树组件，每一项的value数组

        // 递归分类数据
        let recursionCategory = (data) => {
            let len = data.length;
            for(let i = 0;i < len;i++){//循环data参数，匹配回显的value
                itemAry.push(data[i]['label']);//构建分类树数组项,入栈
                for(let j = 0;j < eachAry.length;j++){//遍历子节点分类value，拼凑成数组项value，并终止循环
                    if(eachAry[j] == data[i][fielKey]){//匹配到子节点value
                        echoTreeArr.push(JSON.parse(JSON.stringify(itemAry)));//push进树分类数据
                        eachAry.splice(j,1);//删除以匹配到的value
                        break;
                    }
                }

                if(eachAry.length <= 0){//所有回显value匹配完成后，跳出循环
                    break;
                }else if(data[i].children && data[i].children.length > 0){// 如果存在子分类，递归继续
                    recursionCategory(data[i].children);
                }
                itemAry.pop();//出栈
            }
        }
        recursionCategory(treeData);//调用递归
        return echoTreeArr;
    },
    // 时间转换器
    changeTimeType(time) {
        let date = new Date(time);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h =date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let M =date.getMinutes();
        M = M < 10 ? ('0' + M) : M;
        let s =date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return  y + '-' + m + '-' + d + ' ' + h + ':' + M + ':' + s;
    },

    //date格式转换为"yyyy-MM-dd HH:mm:ss" 字符串
    formatDate(date,type = 1) {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const hours = d.getHours().toString().padStart(2, '0');
        const minutes = d.getMinutes().toString().padStart(2, '0');
        const seconds = d.getSeconds().toString().padStart(2, '0');
        if(type == 1){
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }

        //返回年月日
        console.log(`${year}年${month}月${day}日`)
        return `${year}年${month}月${day}日`;
    },

    // 计算时间间隔
    transDate(start,end) {
        var show_date = '';
        var result = Math.abs(end - start);
        var minute = result / 1000 / 60;
        if (minute == 0 || isNaN(minute) || minute == null) {
            return 0;
        }
        //计算年
        if (minute / 60 / 24 / 30 >= 12) {
            show_date += parseInt(minute / 60 / 24 / 30 / 12) + "年";
        }
        //计算月
        if (minute % (60 * 24 * 30 * 12) != 0 && minute / 60 / 24 >= 30) {
            let t = minute / 60 / 24 / 30;
            t = t - parseInt(minute / 60 / 24 / 30 / 12) * 12;
            show_date += parseInt(t) == 0 ? "0" : parseInt(t) + "个月";
        }
        //计算天
        if (minute % (60 * 24 * 30) != 0 && minute / 60 >= 24) {
            let t = minute / 60 / 24;
            t = t - parseInt(minute / 60 / 24 / 30) * 30;
            show_date += parseInt(t) == 0 ? "0" : parseInt(t) + "天";
        }
        //计算小时
        if (minute % (60 * 24) != 0 && minute >= 60) {
            let t = minute / 60;
            t = t - parseInt(minute / 60 / 24) * 24;
            show_date += parseInt(t) == 0 ? "0" : parseInt(t) + "小时";
        }
        //计算分钟
        if (minute % 60 != 0) {
            //这里处理分钟数取整
            minute = Math.floor(minute - parseInt(minute / 60) * 60) + "分钟"
            show_date += minute ;
        }
        return show_date;
    },
    /**
     * URL对象来解析URL主机名
     * */
    subUrlStr(url,type){
        let strUrl = new URL(url);
        //strUrl.hostname 主机名 strUrl.host 主机名+端口号
        if(type == 1){
            return strUrl.hostname;
        }else if(type == 2){
            return strUrl.host;
        }

    },
    /**
     * 倒计时 天、时分秒
     * */
    countdown(targetDateStr) {
        let targetDate = new Date(targetDateStr);
        let now = new Date();
        let difference = targetDate - now;

        if (difference <= 0) {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }

        let oneDay = 1000 * 60 * 60 * 24;
        let oneHour = 1000 * 60 * 60;
        let oneMinute = 1000 * 60;
        let oneSecond = 1000;

        let days = Math.floor(difference / oneDay);
        let hours = Math.floor((difference % oneDay) / oneHour);
        let minutes = Math.floor((difference % oneHour) / oneMinute);
        let seconds = Math.floor((difference % oneMinute) / oneSecond);

        return {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        };
    },

    // 权限
    toAuth(e) {
        if (e >= 0) {
            if (!store.state.is_login){
                store.commit('setShowLoginPopup', true);
                return false;
            }
        }

        if (e >= 1){
            if (store.state.user.realNameStatus!==2){
                store.commit('setShowAuthPopup','realName');
                return false;
            }
        }

        if (e >= 2) {
            if(store.state.user.userType!==1){
                alert("只有个人专业认证用户才能访问该页面");
                return false;
            }
            if (e===2){
                if (store.state.user.authenticationStatus!==2){
                    store.commit('setShowAuthPopup', 'major');
                    return false;
                }
            }else if (e===3){
                if (store.state.user.schoolAuthStatus!==2){
                    store.commit('setShowAuthPopup', e==="edu");
                    return false;
                }
            }else if (e===4){
                if (store.state.user.majorAuthStatus!==2){
                    store.commit('setShowAuthPopup', e==="career");
                    return false;
                }
            }
        }

        if (e === 5) {
            if (store.state.user.expertAuthStatus!==2){
                store.commit('setShowAuthPopup', 'expert');
                return false;
            }
        }

        return true
    },

    getYear(num){
        // 获取当前日期
        const today = new Date();

        // 获取明年的今天的日期
        const nextYearToday = new Date(today.getFullYear() + num, today.getMonth(), today.getDate());

        // 格式化为年月日格式
        const year = nextYearToday.getFullYear();
        const month = String(nextYearToday.getMonth() + 1).padStart(2, '0');
        const day = String(nextYearToday.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    },
    /**
     * 根据输入的参数代表从当前时间往后多少个月，然后计算每个月的天数
     * */
    getDaysInMonths(numOfMonths) {
        let currentDate = new Date();
        let daysInMonths = [];
        let day = 0;


        for (let i = 0; i < numOfMonths; i++) {
            let year = currentDate.getFullYear();
            let month = currentDate.getMonth() + i;
            let daysInMonth = new Date(year, month + 1, 0).getDate();
            day = day + daysInMonth;
            daysInMonths.push(daysInMonth);
        }
        let params = {
            day:day,
            daysInMonths: daysInMonths
        };
        return params;
    },

    /**
     * 计算从当前时间开始往后多少年的总天数
     * */
    getDaysInYears(numOfYears) {
        let currentDate = new Date();
        let totalDays = 0;

        for (let i = 1; i <= numOfYears; i++) {
            let year = currentDate.getFullYear() + i;
            totalDays += this.isLeapYear(year) ? 366 : 365;
        }

        return totalDays;
    },

    isLeapYear(year) {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }




};

export default utils;
/**
 * 解析字符串的时间
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
    if (arguments.length === 0 || !time) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string')) {
            if ((/^[0-9]+$/.test(time))) {
                // support "1548221490638"
                time = parseInt(time)
            } else {
                // support ISO 8601 with 'T' character
                if (time.includes('T')) {
                    time = time.replace('T', ' ');
                }
                // support safari
                // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
                time = time.replace(new RegExp(/-/gm), '/')
            }
        }
        
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
        return value.toString().padStart(2, '0')
    })
    return time_str
}


/**
 * 格式化时间
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000
    } else {
        time = +time
    }
    const d = new Date(time)
    const now = Date.now()
    
    const diff = (now - d) / 1000
    
    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
        return '1天前'
    }
    if (option) {
        return parseTime(time, option)
    } else {
        return (
            d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
        )
    }
}
